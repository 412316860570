import styled from "styled-components";

export const NotFoundStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  .not-found-block {
    margin: auto;
    display: flex;
    flex-direction: column;
    .title {
      font-size: 100px;
      margin: auto;
    }
    .subtitle {
      margin-top: 30px;
      font-size: 20px;
      margin: auto;
    }
  }
`;
