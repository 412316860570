import { TextLocation, VideoCallSettings } from "../generated/graphql";
import { useTranslation } from "react-i18next";

export function useApiTranslation() {
  const { t, i18n } = useTranslation();
  const translate = (key: string, videoCallSettings: VideoCallSettings) => {
    return (
      videoCallSettings.locale
        .find((el) => el.type === (key === "error.closed" ? TextLocation.Closed : key))
        ?.content.find((el) => el.language === i18n.language)?.value || t(key === TextLocation.Closed ? "error.closed" : key)
    );
  };

  return { translate: translate };
}
