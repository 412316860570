import { ApolloError } from "apollo-client/errors/ApolloError";

export const parseError = (error: ApolloError): string | null => {
  const match = error.message.match(/error\..*$/);

  if (match && match.length) {
    return match[0].toLowerCase();
  }

  return null;
};
