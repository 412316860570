import styled from "styled-components";

export const LoadingStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface MainStyleProps {
  calling: boolean;
}

export const MainStyle = styled.div`
  box-sizing: border-box;
  height: 100%;
  min-height: 100vh;
  overflow: ${(props: MainStyleProps) => (props.calling ? "hidden" : "auto")};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px;
  background-size: cover;
  background-repeat: no-repeat;

  .logo-img {
    display: block;
    max-width: 55vw;
    flex-shrink: 0;
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .modal-close-icon {
    display: block;
    max-width: 90%;
    flex-shrink: 0;
  }

  .intro-title-block {
    h1 {
      font-weight: bold;
      font-size: 1.4em;
      margin-bottom: 20px;
      text-align: center;
    }
  }

  .intro-button-block {
    p {
      max-width: 100%;
      margin: 0 30px;
      text-align: center;
      font-weight: 400;
      margin-bottom: 30px;
      line-height: 150%;
      color: #707070;
    }

    .button {
      margin: auto;
      width: fit-content;

      div {
        &:first-child {
          margin-top: unset;
        }
      }

      .webcam-text {
        font-size: 16px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    p {
      font-size: 1.4em;
    }
  }

  @media screen and (max-width: 500px) {
    padding: 0px;
    padding-top: 30px;

    .intro-title-block {
      p {
        margin-bottom: 0;
      }
    }
  }
`;

const CordaTopBackgroundImage = require("../../assets/images/corda/NETJE BOVEN BLAUW.png");
const CordaBottomBackgroundImage = require("../../assets/images/corda/NETJE ONDER BLAUW.png");

export const CordaMainStyle = styled.div`
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  .top-img {
    z-index: -1;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 1300px;
    background-position: 50% -100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${CordaTopBackgroundImage});
  }

  .bottom-img {
    z-index: -1;
    display: block;
    position: absolute;

    width: 100%;
    height: 100%;
    max-width: 1300px;
    background-position: 50% calc(100% + 100px);
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${CordaBottomBackgroundImage});
  }

  h2,
  h3 {
    max-width: 790px;
    font-weight: 900;
    font-size: 3.6em;
    display: block;
    margin-bottom: 40px;
    text-align: center;
    line-height: 140%;
    white-space: pre-wrap;
  }

  h3 {
    font-size: 3rem;
  }

  @media screen and (max-width: 768px) {
    h3 {
      font-size: 1.6em;
    }
  }
`;

export const CordaButtonStyle = styled.div`
  background-color: #00775f;
  color: white;
  flex-shrink: 0;
  font-weight: 900;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  cursor: pointer;
  transition: 0.2s;
  font-size: 3em;
  box-shadow: inset 0 60px rgba(0, 0, 0, 0);
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  width: 90%;
  padding: 20px;
  max-width: 610px;

  .icon {
    height: 60px;
    margin-right: 15px;
  }

  &:hover {
    box-shadow: inset 0 60px rgba(0, 0, 0, 0.1);
    transform: scale(1.03, 1.03);
  }

  @media screen and (max-width: 768px) {
    font-size: 1.2em;

    .icon {
      height: 30px;
    }
  }
`;
