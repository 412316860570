import { createLocalTracks, LocalTrack } from "twilio-video";

export const getLocation = async () => {
  return new Promise<Position | null>((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve(position);
        },
        (e) => {
          resolve(null);
        },
        {
          enableHighAccuracy: false,
        }
      );
    } else {
      resolve(null);
    }
  });
};

export const getWebcams = async (): Promise<{
  error: string | null;
  data?: { localTracks: LocalTrack[]; webcams: MediaDeviceInfo[] };
}> => {
  let mediaStream;
  const constraints = {
    audio: true,
    video: true,
  };

  /* First call getUserMedia to be sure permissions are granted. "enumerateDevices" doesn't wait for permissions and returns blank device id's on iOS/macOS */

  if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
  }

  if (mediaStream) {
    mediaStream.getTracks().forEach((t) => t.stop());
    const devices = await navigator.mediaDevices.enumerateDevices();
    const webcams = devices.filter((device) => device.kind === "videoinput");

    if (webcams.length <= 0) {
      return { error: "error.no.webcam" };
    }

    const firstWebcam = webcams[0];

    const localTracks = await createLocalTracks({
      audio: false,
      video: {
        width: 320,
        deviceId: firstWebcam.deviceId,
      },
    });
    return { error: null, data: { localTracks, webcams } };
  }
  return { error: "error" };
};
