import styled from "styled-components";

export const VideoPageStyle = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  .mainStyle {
    overflow: hidden;
  }

  .logo {
    display: inline-block;
    z-index: 10;
    position: relative;
    margin-top: 20px;
    margin-left: 20px;
    max-width: 250px;
    transform: scale(0.5, 0.5);
    transform-origin: top left;
  }

  .agentVideo {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    z-index: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    video {
      width: 100%;
      max-height: 70%;
      display: block;
    }
  }

  .userVideo {
    z-index: 2;
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 25vw;
    transition: 0.3s;

    @media screen and (max-width: 600px) {
      width: 35vw;
    }

    video {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
      margin: 0;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
    }
  }
`;

export const EndCallButton = styled.div`
  width: 50px;
  height: 50px;
  background: #e41111;
  border-radius: 25px;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  cursor: pointer;
  color: white;
  z-index: 10;
  transition: 0.2s;

  &:hover {
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.4);
    background: #d71414;
  }

  .end-call-icon {
    margin-bottom: 0px;
  }
`;

export const NoWebcamsWarning = styled.div`
  position: fixed;
  z-index: 5000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 10px;
`;

export const CameraSelectStyle = styled.div`
  position: fixed;
  z-index: 5000;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 10px;

  color: #fff;

  span {
    margin-bottom: 15px;
    font-size: 1.2rem;
  }

  .loader {
    height: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cam {
    height: 50%;
    min-height: 50%;
    max-height: 50%;
    /*background-color: rgba(255,255,255,0.3);*/
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;

    &:hover {
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: #fff;
        opacity: 0.3;
      }
    }
    video {
      width: 100%;
    }
  }
`;

export const CameraSelectSwitcher = styled.div`
  .next,
  .previous {
    width: 25px;
    cursor: pointer;

    &.disabled {
      color: #595959;
    }
  }

  span {
    font-size: 2em;
  }
`;

export const ChangeCamButton = EndCallButton.extend
  ? `
  width: 40px;
  height: 40px;
  right: 25px;
  bottom: 25px;
  top: initial;
  background-color: #fff;
  color: #212121;
  z-index: 50;

  &:hover {
    background-color: #eee;
  }
`
  : null;

export const LoaderStyle = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  flex-direction: column;

  color: ${(props: { primary: string | undefined }) => props.primary};

  span {
    opacity: 0.5;
  }

  img {
    margin: 0;
    width: 60px;
    margin-bottom: 20px;
  }
`;

export const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

export const Modal = styled.div`
  background: #f9f9f9;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  color: #212121;
  padding: 30px;
  text-align: center;
  max-width: 500px;
  width: 95vw;
  position: relative;
  font-size: 1.5em;

  & > .icon {
    font-size: 60px;
  }

  .modal-main {
    max-width: 100%;
    margin: auto;
    .div {
      margin: auto;
    }

    .close {
      width: 25px;
    }
  }

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 15px;
    opacity: 0.6;
    transition: opacity 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  p {
    margin: 25px 0 50px 0;

    line-height: 140%;
  }

  & > div {
    display: inline-flex;
    margin: auto;
    width: 90%;
  }
`;
