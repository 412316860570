import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: require("./locales/en.json") },
    nl: { translation: require("./locales/nl.json") },
    fr: { translation: require("./locales/fr.json") },
    de: { translation: require("./locales/de.json") },
  },
  lng: "nl",
  fallbackLng: "nl",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
