import React from "react";
import { useTranslation } from "react-i18next";
import { Item, LanguageStyle } from "./language-selector.style";
import { i18n as i18nInterface } from "i18next";

export interface LanguageSelectorProps {
  languages: string[];
  defaultLanguage: string;
}

export const LanguageSelector: React.FunctionComponent<LanguageSelectorProps> = ({ languages }) => {
  const { t, i18n } = useTranslation();

  if (languages.length <= 1) {
    return null;
  }

  const renderLanguages = () => {
    return languages.map((lang) => {
      const isActive = lang === i18n.language;
      return (
        <Item key={lang} onClick={() => changeLanguage(lang, i18n)} active={isActive}>
          {t(lang)}
        </Item>
      );
    });
  };

  const changeLanguage = (lang: string, i18n: i18nInterface) => {
    i18n.changeLanguage(lang).then();
  };

  return <LanguageStyle>{renderLanguages()}</LanguageStyle>;
};
