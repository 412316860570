import styled from "styled-components";

interface ButtonStyleProps {
  backgroundColor: string;
  textColor: string;
}

export const ButtonStyle = styled.div`
  background-color: ${(props: ButtonStyleProps) => props.backgroundColor};
  color: ${(props: ButtonStyleProps) => props.textColor};
  flex-shrink: 0;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 45px;
  border-radius: 16px;
  cursor: pointer;
  transition: 0.2s;
  font-size: 22px;
  box-shadow: inset 0 60px rgba(0, 0, 0, 0);
  position: relative;
  margin: auto;
  margin-top: 50px;

  .icon {
    position: absolute;
    left: 25px;
    font-size: 1.2em;
  }

  &:hover {
    box-shadow: inset 0 60px rgba(0, 0, 0, 0.1);
    transform: scale(1.03, 1.03);
  }

  .webcam-icon {
    width: 25px;
    position: absolute;
    left: -5px;
    font-size: 1em;
    margin: auto 0 auto 20px;
  }

  .webcam-icon-svg {
    height: 1rem;
    fill: ${(props: ButtonStyleProps) => props.textColor}F5;
  }

  .webcam-text {
    margin: auto;
  }

  @media (max-width: 600px) {
    padding: 0 70px;
    width: unset;

    .webcam-icon {
      width: 20px;
    }

    .webcam-text {
      margin: auto;
      font-size: 16px;
    }
  }
`;
