import React from "react";
import { NotFoundStyle } from "./not-found.style";
import { useTranslation } from "react-i18next";

export const NotFound: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <NotFoundStyle>
      <div className="not-found-block">
        <div className="title">404</div>
        <div className="subtitle">{t("pageNotFound")}</div>
      </div>
    </NotFoundStyle>
  );
};
