import styled from "styled-components";

export const LanguageStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-shrink: 0;
`;

export const Item = styled.div`
  opacity: ${(props: { active: boolean }) => (props.active ? 1 : 0.5)};
  margin-right: 15px;
  margin-bottom: 15px;
  margin-top: 20px;

  transition: opacity 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
  font-size: 1.2em;
  font-weight: bold;

  &:hover {
    opacity: 1;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;
