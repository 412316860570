import { Modal, Overlay } from "../../pages/video-call/video-call-page.style";
import { Button } from "../button/button";
import React from "react";
import { useTranslation } from "react-i18next";
import { TextLocation, VideoCallSettings } from "../../generated/graphql";
import { useApiTranslation } from "../../helpers/apiTranslationHook";

export interface TryAgainProps {
  callSettings: VideoCallSettings;
  callHandler: (state: boolean) => any;
  tryAgain: () => Promise<any>;
}

export const TryAgain: React.FunctionComponent<TryAgainProps> = ({ callHandler, tryAgain, callSettings }) => {
  const { t } = useTranslation();
  const { translate } = useApiTranslation();
  return (
    <Overlay onClick={() => callHandler(false)}>
      <Modal onClick={(ev) => ev.stopPropagation()}>
        <i onClick={() => callHandler(false)} className="icon close" />
        <i className="icon missed-call" />
        <p>{translate(TextLocation.Unavailable, callSettings)}</p>
        <Button onClick={tryAgain} colors={callSettings?.colors}>
          {t("try.again")}
        </Button>
      </Modal>
    </Overlay>
  );
};
