import React, { useEffect, useState } from "react";
import {
  CustomerLocation,
  TextLocation,
  useGetVideoCallSettingsQuery,
  useSettingsUpdatedSubscription,
  VideoCallSettings,
} from "../../generated/graphql";
import { MainStyle } from "./intro-page.style";
import { LanguageSelector } from "../../components/language-selector/language-selector";
import { Button } from "../../components/button/button";
import { useParams } from "react-router-dom";
import { VideoCallPage } from "../video-call/video-call-page";
import { NotFound } from "../not-found/not-found";
import { Loading } from "../../components/loading/loading";
import { useApiTranslation } from "../../helpers/apiTranslationHook";

export interface RouteParams {
  platformId: string;
  customerId: string;
  locationId: string;
}

export interface IntroPageProps {}

export interface IntroPageState {
  calling: boolean;
}

export const IntroPage: React.FunctionComponent<IntroPageProps> = (routerParams) => {
  const [calling, setCalling] = useState(false);
  const { platformId, customerId } = useParams<RouteParams>();
  const { translate } = useApiTranslation();
  const { data, loading, error } = useGetVideoCallSettingsQuery({
    variables: { platformId: platformId, customerId: customerId },
  });
  const [callSettings, setCallSettings] = useState<VideoCallSettings | null>(null);
  const { data: updatedSettings, error: subscriptionError, loading: subscriptionLoading } = useSettingsUpdatedSubscription({
    variables: { customerId, platformId },
  });

  useEffect(() => {
    if (data && !loading && !error) {
      setCallSettings(data.videoCallSettings || null);
    }
  }, [data, loading, error]);

  useEffect(() => {
    if (updatedSettings && !subscriptionError && !subscriptionLoading) {
      setCallSettings({ ...(callSettings as VideoCallSettings), ...{ locations: updatedSettings.settingsUpdated.locations as CustomerLocation[] } });
    }
  }, [updatedSettings, subscriptionLoading, subscriptionError, callSettings]);

  const call = (value: boolean) => {
    setCalling(value);
  };

  return (
    <React.Fragment>
      {(!platformId || !customerId) && <div></div>}
      {loading && <Loading></Loading>}
      {!loading && !callSettings && <NotFound></NotFound>}
      {!loading && callSettings && platformId && customerId && (
        <MainStyle
          calling={false}
          style={{
            backgroundColor: callSettings?.colors.background,
            color: callSettings?.colors.text,
            backgroundImage: `url(${callSettings?.backgroundImage})`,
          }}
        >
          {calling && <VideoCallPage callHandler={call} callSettings={callSettings} />}
          {!calling && (
            <>
              <img alt="Logo" src={callSettings?.logoImage} className="logo-img" />
              <div className="content">
                <div>
                  <div className="intro-title-block">
                    <h1>{translate(TextLocation.Welcome, callSettings)}</h1>
                  </div>
                </div>

                <div className="intro-button-block">
                  <p>{translate(TextLocation.Intro, callSettings)}</p>

                  <div className="button">
                    <Button call={true} onClick={() => call(true)} colors={callSettings?.colors}>
                      {translate(TextLocation.InitiateCall, callSettings)}
                    </Button>
                  </div>
                </div>

                <LanguageSelector defaultLanguage={callSettings?.defaultLanguage || "nl"} languages={callSettings?.languages || ["nl"]} />
              </div>
            </>
          )}
        </MainStyle>
      )}
    </React.Fragment>
  );
};
