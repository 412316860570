import { LoaderStyle } from "../../pages/video-call/video-call-page.style";
import React from "react";
import { useTranslation } from "react-i18next";
import { AgentsStatus, VideoCallSettings } from "../../generated/graphql";

interface VideoLoaderProps {
  callSettings?: VideoCallSettings | null;
  selectedWebcamId?: string | null;
  location?: Position | null;
  token?: string | null;
  agentStatus?: AgentsStatus | null;
  destroyVideo: () => any;
}

export const VideoLoader: React.FunctionComponent<VideoLoaderProps> = ({
  selectedWebcamId,
  location,
  token,
  callSettings,
  agentStatus,
  destroyVideo,
}) => {
  const { t } = useTranslation();
  let message = "loading.webcams";

  if (selectedWebcamId) {
    message = "loading.location";
  }

  if (location === null || location) {
    message = "loading.sendingRequest";
  }

  if (token) {
    message = "loading.waiting";
  }

  if (agentStatus === "AWAY") {
    message = "loading.lookingForAgents";
    destroyVideo();
  }
  return (
    <LoaderStyle primary={callSettings?.colors.text}>
      <img src={require("../../assets/spinner.svg")} alt="" />
      {t(message)}
    </LoaderStyle>
  );
};
