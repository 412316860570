import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

const websocketUrl = process.env.REACT_APP_GRAPHQL_URL_WS || "ws://localhost:4000/graphql-ws";
const httpUrl = process.env.REACT_APP_GRAPHQL_URL_HTTP || "http://localhost:4000/graphql";

// @ts-ignore
const hasSubscriptionOperation = ({ query: { definitions } }) =>
  definitions.some(
    // @ts-ignore
    ({ kind, operation }) => kind === "OperationDefinition" && operation === "subscription"
  );

const link = ApolloLink.split(
  hasSubscriptionOperation,
  new WebSocketLink({
    uri: websocketUrl,
    options: { reconnect: true },
  }),
  new HttpLink({
    uri: httpUrl,
  })
);
export const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});
