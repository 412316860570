import { LoadingStyle } from "./loading-style";
import { Overlay } from "../../pages/video-call/video-call-page.style";
import React from "react";
import { useTranslation } from "react-i18next";

export const Loading: React.FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Overlay>
      <LoadingStyle>
        <img src={require("../../assets/spinner.svg")} alt="null" />
        <div className="text">{t("loading")}</div>
      </LoadingStyle>
    </Overlay>
  );
};
