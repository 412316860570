import gql from "graphql-tag";
import * as React from "react";
import * as ApolloReactCommon from "@apollo/react-common";
import * as ApolloReactComponents from "@apollo/react-components";
import * as ApolloReactHoc from "@apollo/react-hoc";
import * as ApolloReactHooks from "@apollo/react-hooks";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type Query = {
  __typename?: "Query";
  getCurrentCompany: CompanyResultModel;
  getCurrentCommunicationCompany: CompanyResultModel;
  customer: Customer;
  customerChatData: CustomerChatData;
  customers: CustomersResult;
  sessionCustomer: Customer;
  pairedChannels: PairedChannelsResult;
  conversationStructuredTypes: ConversationStructuredTypesResult;
  roles: RolesResult;
  users: UsersResult;
  user: User;
  logout: Scalars["Boolean"];
  getCurrentUser: User;
  userStatus: UserStatus;
  customerCrmContacts: CustomerCrmContactsResult;
  customerCrmContactCommunicationTypes: CustomerCrmContactCommunicationTypesResult;
  customerCrmContactGroups: CustomerCrmContactGroupsResult;
  meccaConversation: MeccaConversationModel;
  meccaConversations: MeccaConversationResultModel;
  meccaConversationsByFilter: Array<MeccaConversationModel>;
  checkConversationLocked: Scalars["Boolean"];
  socialConversations: SocialConversationsResultModel;
  socialConversation: SocialConversationModel;
  customerAttachments: CustomerAttachmentsResult;
  invoices: InvoicesResult;
  incomingCalls: IncomingCallResult;
  outgoingCalls: OutgoingCallResult;
  countries: CountriesResult;
  customerFixedInstructions: CustomerFixedInstructionsResult;
  customerTemporaryInstructions: CustomerTemporaryInstructionsResult;
  dashboard: Dashboard;
  agentsStatus: AgentsStatusResponse;
  videoCallSettings?: Maybe<VideoCallSettings>;
  defaultLocale: Array<DefaultLocale>;
  videoCall: VideoCall;
  videoCalls: VideoCallResult;
  incomingCallReporting: IncomingCallReportingResult;
  outgoingCallReporting: OutgoingCallReportingResult;
  downloadIncomingCallReporting: DownloadResult;
  downloadOutgoingCallReporting: DownloadResult;
  operationReporting: OperationReportingResult;
  videoCallReporting: VideoCallReportingResult;
  downloadVideoCallReporting: DownloadResult;
  search: SearchResult;
  standby: StandbysResult;
  theme: Theme;
};

export type QueryCustomerArgs = {
  filter?: Maybe<CustomerFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<SortInput>>;
  search?: Maybe<PropertySearch>;
};

export type QueryCustomersArgs = {
  filter?: Maybe<CustomerFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<SortInput>>;
  search?: Maybe<PropertySearch>;
};

export type QueryPairedChannelsArgs = {
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<SortInput>>;
  filter?: Maybe<PairedChannelFilterInput>;
};

export type QueryConversationStructuredTypesArgs = {
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<SortInput>>;
};

export type QueryRolesArgs = {
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<SortInput>>;
  filter?: Maybe<RolesFilterInput>;
};

export type QueryUsersArgs = {
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<SortInput>>;
  filter?: Maybe<UsersFilterInput>;
};

export type QueryUserArgs = {
  id: Scalars["ID"];
};

export type QueryCustomerCrmContactsArgs = {
  filter?: Maybe<CustomerCrmContactFilter>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<SortInput>>;
};

export type QueryCustomerCrmContactCommunicationTypesArgs = {
  filter?: Maybe<CustomerCrmContactCommunicationTypeFilter>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<SortInput>>;
};

export type QueryCustomerCrmContactGroupsArgs = {
  filter?: Maybe<CustomerCrmContactGroupFilter>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<SortInput>>;
};

export type QueryMeccaConversationArgs = {
  id: Scalars["ID"];
};

export type QueryMeccaConversationsArgs = {
  sort?: Maybe<Array<SortInput>>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  filter?: Maybe<FilterMeccaConversationInputModel>;
};

export type QueryMeccaConversationsByFilterArgs = {
  till?: Maybe<Scalars["DateTime"]>;
  from?: Maybe<Scalars["DateTime"]>;
  ids?: Maybe<Array<Scalars["ID"]>>;
};

export type QueryCheckConversationLockedArgs = {
  agentMessageId: Scalars["ID"];
  conversationId: Scalars["ID"];
};

export type QuerySocialConversationsArgs = {
  sort?: Maybe<Array<SortInput>>;
  skip?: Maybe<Scalars["Int"]>;
  limit?: Maybe<Scalars["Int"]>;
  filter?: Maybe<SocialConversationFilterModel>;
};

export type QuerySocialConversationArgs = {
  id: Scalars["ID"];
};

export type QueryCustomerAttachmentsArgs = {
  filter?: Maybe<CustomerAttachmentFilter>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<SortInput>>;
};

export type QueryInvoicesArgs = {
  filter?: Maybe<InvoiceFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<SortInput>>;
};

export type QueryIncomingCallsArgs = {
  filter?: Maybe<IncomingCallFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  ignoreLimitOffset?: Maybe<Scalars["Boolean"]>;
  sort?: Maybe<Array<SortInput>>;
};

export type QueryOutgoingCallsArgs = {
  filter?: Maybe<OutgoingCallFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  ignoreLimitOffset?: Maybe<Scalars["Boolean"]>;
  sort?: Maybe<Array<SortInput>>;
};

export type QueryCountriesArgs = {
  filter?: Maybe<CountryFilter>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<SortInput>>;
};

export type QueryCustomerFixedInstructionsArgs = {
  filter?: Maybe<CustomerFixedInstructionFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<SortInput>>;
};

export type QueryCustomerTemporaryInstructionsArgs = {
  filter?: Maybe<CustomerTemporaryInstructionFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<SortInput>>;
};

export type QueryAgentsStatusArgs = {
  platformId?: Maybe<Scalars["ID"]>;
  customerId?: Maybe<Scalars["ID"]>;
};

export type QueryVideoCallSettingsArgs = {
  platformId?: Maybe<Scalars["ID"]>;
  customerId?: Maybe<Scalars["ID"]>;
};

export type QueryVideoCallArgs = {
  callId: Scalars["ID"];
};

export type QueryVideoCallsArgs = {
  filter?: Maybe<VideoCallFilterInput>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<SortInput>>;
};

export type QueryIncomingCallReportingArgs = {
  filter?: Maybe<IncomingCallReportingFilterInput>;
};

export type QueryOutgoingCallReportingArgs = {
  filter?: Maybe<OutgoingCallReportingFilterInput>;
};

export type QueryDownloadIncomingCallReportingArgs = {
  filter?: Maybe<IncomingCallReportingFilterInput>;
  sort?: Maybe<Array<SortInput>>;
  lang?: Maybe<LangEnum>;
};

export type QueryDownloadOutgoingCallReportingArgs = {
  filter?: Maybe<OutgoingCallReportingFilterInput>;
  sort?: Maybe<Array<SortInput>>;
  lang?: Maybe<LangEnum>;
};

export type QueryOperationReportingArgs = {
  filter?: Maybe<OperationReportingFilterInput>;
};

export type QueryVideoCallReportingArgs = {
  filter?: Maybe<VideoCallReportingFilterInput>;
};

export type QueryDownloadVideoCallReportingArgs = {
  filter?: Maybe<VideoCallReportingFilterInput>;
  sort?: Maybe<Array<SortInput>>;
  lang?: Maybe<LangEnum>;
};

export type QuerySearchArgs = {
  filter?: Maybe<SearchFilterInput>;
  search: Scalars["String"];
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<SortInput>>;
};

export type QueryStandbyArgs = {
  filter?: Maybe<StandbyFilter>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<SortInput>>;
};

export type CompanyResultModel = {
  __typename?: "CompanyResultModel";
  id: Scalars["Float"];
  profileName: Scalars["String"];
  name?: Maybe<Scalars["String"]>;
  address1?: Maybe<Scalars["String"]>;
  address2?: Maybe<Scalars["String"]>;
};

export type CustomerFilterInput = {
  ids?: Maybe<Array<Scalars["ID"]>>;
};

export type SortInput = {
  field: Scalars["String"];
  order: SortOrder;
};

/** ASC or DESC */
export enum SortOrder {
  Asc = "ASC",
  Desc = "DESC",
}

export type PropertySearch = {
  fields?: Maybe<Array<Scalars["String"]>>;
  search: Scalars["String"];
};

export type Customer = {
  __typename?: "Customer";
  id: Scalars["ID"];
  platformId?: Maybe<Scalars["ID"]>;
  appointmentContractYn?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  parentId?: Maybe<Scalars["ID"]>;
  groupId?: Maybe<Scalars["ID"]>;
  info?: Maybe<CustomerInfo>;
};

export type CustomerInfo = {
  __typename?: "CustomerInfo";
  contactInformationList: Array<CustomerContactInformation>;
  welcomeMessage?: Maybe<Scalars["String"]>;
};

export type CustomerContactInformation = {
  __typename?: "CustomerContactInformation";
  type: CustomerContactInformationTypes;
  address?: Maybe<Address>;
  emailAddress?: Maybe<Scalars["String"]>;
  faxNumber?: Maybe<Scalars["String"]>;
  mobilePhoneNumber?: Maybe<Scalars["String"]>;
  phoneNumber1?: Maybe<Scalars["String"]>;
  phoneNumber2?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

/** The different types of customer contact information */
export enum CustomerContactInformationTypes {
  Business_1 = "BUSINESS_1",
  Business_2 = "BUSINESS_2",
  Private_1 = "PRIVATE_1",
  Private_2 = "PRIVATE_2",
}

export type Address = {
  __typename?: "Address";
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  streetNumber?: Maybe<Scalars["String"]>;
  box?: Maybe<Scalars["String"]>;
};

export type CustomerChatData = {
  __typename?: "CustomerChatData";
  guid: Scalars["String"];
  username: Scalars["String"];
  extension: Scalars["Float"];
  mainFicheId: Scalars["ID"];
  customerId: Scalars["ID"];
  userEmail: Scalars["ID"];
  serviceGroup: Scalars["String"];
};

export type CustomersResult = {
  __typename?: "CustomersResult";
  count: Scalars["Int"];
  items: Array<Customer>;
};

export type PairedChannelFilterInput = {
  account?: Maybe<Scalars["ID"]>;
  channelIdentifier?: Maybe<Scalars["String"]>;
};

export type PairedChannelsResult = {
  __typename?: "PairedChannelsResult";
  count: Scalars["Int"];
  items: Array<PairedChannel>;
};

export type PairedChannel = {
  id: Scalars["ID"];
  account: Scalars["ID"];
  active: Scalars["Boolean"];
  channelIdentifier?: Maybe<Scalars["String"]>;
  deleted: Scalars["Boolean"];
  redirect?: Maybe<Scalars["Boolean"]>;
  redirectDelay?: Maybe<Scalars["Int"]>;
  type: ChannelType;
};

/** Possible channels for communication */
export enum ChannelType {
  Blank = "BLANK",
  CallMecca = "CALL_MECCA",
  TextFacebook = "TEXT_FACEBOOK",
  TextTwitter = "TEXT_TWITTER",
  TextWhatsapp = "TEXT_WHATSAPP",
  VideoMemo = "VIDEO_MEMO",
}

export type ConversationStructuredTypesResult = {
  __typename?: "ConversationStructuredTypesResult";
  count: Scalars["Int"];
  items: Array<ConversationStructuredType>;
};

export type ConversationStructuredType = {
  __typename?: "ConversationStructuredType";
  name: Scalars["String"];
  fields: Array<ConversationStructuredTypeField>;
};

export type ConversationStructuredTypeField = {
  __typename?: "ConversationStructuredTypeField";
  sequence: Scalars["Float"];
  fieldType: ConversationStructuredTypeFieldType;
  label: Scalars["String"];
  description: Scalars["String"];
  required: Scalars["Boolean"];
  readonly: Scalars["Boolean"];
  isEditableByCustomer: Scalars["Boolean"];
  defaultValue?: Maybe<Scalars["String"]>;
  options?: Maybe<Array<Scalars["String"]>>;
};

export type ConversationStructuredTypeFieldType = {
  __typename?: "ConversationStructuredTypeFieldType";
  name: Scalars["String"];
  regExForValidation?: Maybe<Scalars["String"]>;
  regExForValidChars?: Maybe<Scalars["String"]>;
  mask?: Maybe<Scalars["String"]>;
  maxLength: Scalars["Float"];
  errorMessage?: Maybe<Scalars["String"]>;
};

export type RolesFilterInput = {
  value?: Maybe<Scalars["String"]>;
};

export type RolesResult = {
  __typename?: "RolesResult";
  count: Scalars["Int"];
  items: Array<Role>;
};

export type Role = {
  __typename?: "Role";
  name: Scalars["String"];
  permissions: Array<Scalars["String"]>;
};

export type UsersFilterInput = {
  value?: Maybe<Scalars["String"]>;
};

export type UsersResult = {
  __typename?: "UsersResult";
  count: Scalars["Int"];
  items: Array<User>;
};

export type User = {
  __typename?: "User";
  id?: Maybe<Scalars["String"]>;
  emailAddress?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
  locale?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  customers?: Maybe<Array<Scalars["String"]>>;
  role?: Maybe<UserRole>;
};

/** User role */
export enum UserRole {
  Admin = "admin",
  Superadmin = "superadmin",
  Agent = "agent",
}

export type UserStatus = {
  __typename?: "UserStatus";
  active: Scalars["Boolean"];
};

export type CustomerCrmContactFilter = {
  ids?: Maybe<Array<Scalars["Int"]>>;
  communicationValueSearch?: Maybe<Scalars["String"]>;
};

export type CustomerCrmContactsResult = {
  __typename?: "CustomerCrmContactsResult";
  count: Scalars["Int"];
  items: Array<CustomerCrmContact>;
};

export type CustomerCrmContact = {
  __typename?: "CustomerCrmContact";
  id: Scalars["Int"];
  title?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName: Scalars["String"];
  sex?: Maybe<CustomerCrmContactSexes>;
  functionName?: Maybe<Scalars["String"]>;
  addresses?: Maybe<Array<CustomerCrmContactAddress>>;
  communications?: Maybe<Array<CustomerCrmContactCommunication>>;
  groups?: Maybe<Array<CustomerCrmContactGroupRelation>>;
};

/** The different types of customer crm contact genders */
export enum CustomerCrmContactSexes {
  Male = "MALE",
  Female = "FEMALE",
}

export type CustomerCrmContactAddress = {
  __typename?: "CustomerCrmContactAddress";
  id: Scalars["Int"];
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  address?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
};

export type CustomerCrmContactCommunication = {
  __typename?: "CustomerCrmContactCommunication";
  type: CustomerCrmContactCommunicationType;
  sequence?: Maybe<Scalars["Int"]>;
  value?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  forwardStatus: CustomerCrmContactCommunicationStatusses;
  forwardCondition?: Maybe<Scalars["String"]>;
  passThroughStatus: CustomerCrmContactCommunicationStatusses;
  passThroughCondition?: Maybe<Scalars["String"]>;
};

export type CustomerCrmContactCommunicationType = {
  __typename?: "CustomerCrmContactCommunicationType";
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  category: CustomerCrmContactCommunicationTypeCategories;
};

/** The different types of customer crm contact communication categories */
export enum CustomerCrmContactCommunicationTypeCategories {
  None = "NONE",
  Landline = "LANDLINE",
  Mobile = "MOBILE",
  Fax = "FAX",
  Email = "EMAIL",
}

/** The different types of customer crm contact communication statusses */
export enum CustomerCrmContactCommunicationStatusses {
  Always = "ALWAYS",
  UnderConditions = "UNDER_CONDITIONS",
  Never = "NEVER",
}

export type CustomerCrmContactGroupRelation = {
  __typename?: "CustomerCrmContactGroupRelation";
  groupId: Scalars["Int"];
  group: CustomerCrmContactGroup;
  subGroupId: Scalars["Int"];
  subGroup: CustomerCrmContactSubGroup;
};

export type CustomerCrmContactGroup = {
  __typename?: "CustomerCrmContactGroup";
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  subGroups?: Maybe<Array<CustomerCrmContactSubGroup>>;
};

export type CustomerCrmContactSubGroup = {
  __typename?: "CustomerCrmContactSubGroup";
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
};

export type CustomerCrmContactCommunicationTypeFilter = {
  ids: Array<Scalars["Int"]>;
};

export type CustomerCrmContactCommunicationTypesResult = {
  __typename?: "CustomerCrmContactCommunicationTypesResult";
  count: Scalars["Int"];
  items: Array<CustomerCrmContactCommunicationType>;
};

export type CustomerCrmContactGroupFilter = {
  ids: Array<Scalars["Int"]>;
};

export type CustomerCrmContactGroupsResult = {
  __typename?: "CustomerCrmContactGroupsResult";
  count: Scalars["Int"];
  items: Array<CustomerCrmContactGroup>;
};

export type MeccaConversationModel = {
  id: Scalars["ID"];
  channelType: ChannelType;
  createdAt: Scalars["DateTime"];
  createdBy: User;
  lastUpdatedAt: Scalars["DateTime"];
  events: Array<EventModel>;
  flagged?: Maybe<Scalars["Boolean"]>;
  participants: Array<MeccaConversationParticipant>;
  priority: Scalars["Float"];
  lastMessageTime: Scalars["DateTime"];
  read: Scalars["Boolean"];
  status: ConversationStatus;
  related: Array<MeccaConversationModel>;
};

export type EventModel = {
  eventTime: Scalars["DateTime"];
  type: EventTypes;
};

/** The different types of events */
export enum EventTypes {
  ConversationCreated = "ConversationCreated",
  ConversationUpdated = "ConversationUpdated",
  ConversationForwarded = "ConversationForwarded",
  ConversationReaded = "ConversationReaded",
  ConversationFlagged = "ConversationFlagged",
  ConversationUnflagged = "ConversationUnflagged",
  ConversationArchived = "ConversationArchived",
  ConversationClosed = "ConversationClosed",
  ConversationStatusChanged = "ConversationStatusChanged",
  ConversationPriorityChanged = "ConversationPriorityChanged",
}

export type MeccaConversationParticipant = {
  __typename?: "MeccaConversationParticipant";
  channel: ChannelType;
  channelId: Scalars["ID"];
  destination: Scalars["String"];
  source: Scalars["String"];
};

/** The different conversation statusses */
export enum ConversationStatus {
  Callback = "CALLBACK",
  Cancellation = "CANCELLATION",
  Finished = "FINISHED",
  Info = "INFO",
  Open = "OPEN",
  Question = "QUESTION",
  Urgent = "URGENT",
  Important = "IMPORTANT",
  High = "HIGH",
  CallbackHimself = "CALLBACK_HIMSELF",
  EmailBack = "EMAIL_BACK",
}

export type FilterMeccaConversationInputModel = {
  read?: Maybe<Scalars["Boolean"]>;
  channelType?: Maybe<Array<ChannelType>>;
  flagged?: Maybe<Scalars["Boolean"]>;
  from?: Maybe<Scalars["DateTime"]>;
  inboxType?: Maybe<Scalars["String"]>;
  priorityFrom?: Maybe<Scalars["Int"]>;
  priorityTill?: Maybe<Scalars["Int"]>;
  till?: Maybe<Scalars["DateTime"]>;
  statusses?: Maybe<Array<ConversationStatus>>;
};

export type MeccaConversationResultModel = {
  __typename?: "MeccaConversationResultModel";
  count: Scalars["Int"];
  items: Array<MeccaConversationModel>;
};

export type SocialConversationFilterModel = {
  read?: Maybe<Scalars["Boolean"]>;
  channelType?: Maybe<Array<ChannelType>>;
  archived?: Maybe<Scalars["Boolean"]>;
  flagged?: Maybe<Scalars["Boolean"]>;
  from?: Maybe<Scalars["DateTime"]>;
  priorityFrom?: Maybe<Scalars["Int"]>;
  priorityTill?: Maybe<Scalars["Int"]>;
  till?: Maybe<Scalars["DateTime"]>;
  statusses?: Maybe<Array<ConversationStatus>>;
};

export type SocialConversationsResultModel = {
  __typename?: "SocialConversationsResultModel";
  count: Scalars["Int"];
  items: Array<SocialConversationModel>;
};

export type SocialConversationModel = {
  __typename?: "SocialConversationModel";
  id: Scalars["ID"];
  archived: Scalars["Boolean"];
  channelType?: Maybe<ChannelType>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  createdBy?: Maybe<User>;
  lastUpdatedAt?: Maybe<Scalars["DateTime"]>;
  events?: Maybe<Array<EventModel>>;
  preview?: Maybe<SocialTextConversationPreviewModel>;
  multipleResponsesTill?: Maybe<Scalars["DateTime"]>;
  canSendResponse?: Maybe<Scalars["Boolean"]>;
  flagged: Scalars["Boolean"];
  participants?: Maybe<Array<SocialConversationParticipantModel>>;
  priority: Scalars["Int"];
  read: Scalars["Boolean"];
  status?: Maybe<ConversationStatus>;
  related?: Maybe<Array<SocialConversationModel>>;
  channel?: Maybe<PairedChannel>;
  channelsActive: Scalars["Boolean"];
  messages: Array<Message>;
};

export type SocialTextConversationPreviewModel = {
  __typename?: "SocialTextConversationPreviewModel";
  type: TextConversationPreviewTypes;
  messageTime: Scalars["DateTime"];
  content?: Maybe<Scalars["String"]>;
  attachments?: Maybe<Array<Attachment>>;
};

/** The different types of preview */
export enum TextConversationPreviewTypes {
  TextAndAttachment = "TextAndAttachment",
  TextAndAttachmentWithStructuredMessage = "TextAndAttachmentWithStructuredMessage",
}

export type Attachment = {
  __typename?: "Attachment";
  id: Scalars["ID"];
  fileName?: Maybe<Scalars["String"]>;
  url: Scalars["String"];
};

export type SocialConversationParticipantModel = {
  __typename?: "SocialConversationParticipantModel";
  channel: ChannelType;
  channelId: Scalars["ID"];
  destination: Scalars["String"];
  source: Scalars["String"];
};

export type Message = {
  __typename?: "Message";
  id: Scalars["ID"];
  attachments?: Maybe<Array<Attachment>>;
  channelType: ChannelType;
  content?: Maybe<Scalars["String"]>;
  conversationId: Scalars["ID"];
  destination: Scalars["String"];
  eventTime: Scalars["DateTime"];
  external?: Maybe<Scalars["Boolean"]>;
  messageTime: Scalars["DateTime"];
  sender: User;
  serviceId: Scalars["String"];
  source: Scalars["String"];
};

export type CustomerAttachmentFilter = {
  ids: Array<Scalars["Int"]>;
};

export type CustomerAttachmentsResult = {
  __typename?: "CustomerAttachmentsResult";
  count: Scalars["Int"];
  items: Array<CustomerAttachmentWithVersions>;
};

export type CustomerAttachmentWithVersions = {
  __typename?: "CustomerAttachmentWithVersions";
  id: Scalars["Int"];
  name: Scalars["String"];
  validFrom: Scalars["DateTime"];
  validTill: Scalars["DateTime"];
  type: Scalars["String"];
  attachment: Attachment;
  active: Scalars["Boolean"];
  version: Scalars["Float"];
  versions: Array<CustomerAttachment>;
};

export type CustomerAttachment = {
  __typename?: "CustomerAttachment";
  id: Scalars["Int"];
  name: Scalars["String"];
  validFrom: Scalars["DateTime"];
  validTill: Scalars["DateTime"];
  type: Scalars["String"];
  attachment: Attachment;
  active: Scalars["Boolean"];
  version: Scalars["Float"];
};

export type InvoiceFilterInput = {
  ids?: Maybe<Array<Scalars["Int"]>>;
  paid?: Maybe<Scalars["Boolean"]>;
};

export type InvoicesResult = {
  __typename?: "InvoicesResult";
  count: Scalars["Int"];
  items: Array<Invoice>;
};

export type Invoice = {
  __typename?: "Invoice";
  id: Scalars["Int"];
  amount: Scalars["Float"];
  date: Scalars["DateTime"];
  dueDate?: Maybe<Scalars["DateTime"]>;
  year: Scalars["Int"];
  month: Scalars["Int"];
  invoiceNr: Scalars["Int"];
  paid: Scalars["Boolean"];
  type: InvoiceTypes;
  attachment?: Maybe<Attachment>;
};

/** The different types of invoices */
export enum InvoiceTypes {
  Invoice = "INVOICE",
  CreditNota = "CREDIT_NOTA",
}

export type IncomingCallFilterInput = {
  from?: Maybe<Scalars["DateTime"]>;
  to?: Maybe<Scalars["DateTime"]>;
};

export type IncomingCallResult = {
  __typename?: "IncomingCallResult";
  count: Scalars["Int"];
  items: Array<IncomingCall>;
};

export type IncomingCall = {
  __typename?: "IncomingCall";
  id: Scalars["ID"];
  type: DayNightCallTypes;
  date: Scalars["DateTime"];
  duration: Scalars["Int"];
  callerInfo?: Maybe<Scalars["String"]>;
};

/** The different types of day/night calls */
export enum DayNightCallTypes {
  Daylight = "DAYLIGHT",
  Night = "NIGHT",
}

export type OutgoingCallFilterInput = {
  from?: Maybe<Scalars["DateTime"]>;
  to?: Maybe<Scalars["DateTime"]>;
};

export type OutgoingCallResult = {
  __typename?: "OutgoingCallResult";
  count: Scalars["Int"];
  items: Array<OutgoingCall>;
};

export type OutgoingCall = {
  __typename?: "OutgoingCall";
  id: Scalars["ID"];
  type: DayNightCallTypes;
  date: Scalars["DateTime"];
  duration: Scalars["Int"];
  callerInfo?: Maybe<Scalars["String"]>;
};

export type CountryFilter = {
  iso: Array<Scalars["String"]>;
};

export type CountriesResult = {
  __typename?: "CountriesResult";
  count: Scalars["Int"];
  items: Array<Country>;
};

export type Country = {
  __typename?: "Country";
  iso: Scalars["String"];
  name: Scalars["String"];
};

export type CustomerFixedInstructionFilterInput = {
  ids?: Maybe<Array<Scalars["Int"]>>;
};

export type CustomerFixedInstructionsResult = {
  __typename?: "CustomerFixedInstructionsResult";
  count: Scalars["Int"];
  items: Array<CustomerFixedInstruction>;
};

export type CustomerFixedInstruction = {
  __typename?: "CustomerFixedInstruction";
  id: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
  name: Scalars["String"];
  content: Scalars["String"];
};

export type CustomerTemporaryInstructionFilterInput = {
  ids?: Maybe<Array<Scalars["Int"]>>;
  type: InstructionTypes;
};

/** The different types of invoices */
export enum InstructionTypes {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Future = "FUTURE",
}

export type CustomerTemporaryInstructionsResult = {
  __typename?: "CustomerTemporaryInstructionsResult";
  count: Scalars["Int"];
  items: Array<CustomerTemporaryInstruction>;
};

export type CustomerTemporaryInstruction = {
  __typename?: "CustomerTemporaryInstruction";
  id: Scalars["Int"];
  updatedAt: Scalars["DateTime"];
  name: Scalars["String"];
  content: Scalars["String"];
  validFrom: Scalars["DateTime"];
  validUntill: Scalars["DateTime"];
  active: Scalars["Boolean"];
  deleted: Scalars["Boolean"];
};

export type Dashboard = {
  __typename?: "Dashboard";
  meccaConversations: DashboardConversation;
  socialConversations: DashboardConversation;
  invoices: DashboardInvoice;
  notifications: NotificationsResult;
};

export type DashboardNotificationsArgs = {
  filter?: Maybe<NotificationFilter>;
  limit?: Maybe<Scalars["Int"]>;
  skip?: Maybe<Scalars["Int"]>;
  sort?: Maybe<Array<SortInput>>;
};

export type DashboardConversation = {
  __typename?: "DashboardConversation";
  total: Scalars["Int"];
  countsByStatus: Array<DashboardConversationStatus>;
};

export type DashboardConversationStatus = {
  __typename?: "DashboardConversationStatus";
  status: ConversationStatus;
  total: Scalars["Int"];
};

export type DashboardInvoice = {
  __typename?: "DashboardInvoice";
  current?: Maybe<Scalars["Float"]>;
  open: Scalars["Int"];
};

export type NotificationFilter = {
  types: Array<NotificationTypes>;
};

/** The different types of notifications */
export enum NotificationTypes {
  Invoice = "INVOICE",
  Conversation = "CONVERSATION",
}

export type NotificationsResult = {
  __typename?: "NotificationsResult";
  count: Scalars["Int"];
  items: Array<Notification>;
};

export type Notification = {
  date: Scalars["DateTime"];
  type: NotificationTypes;
};

export type AgentsStatusResponse = {
  __typename?: "AgentsStatusResponse";
  agentsStatus: AgentsStatus;
};

/** Agents status for Customer */
export enum AgentsStatus {
  Available = "AVAILABLE",
  Away = "AWAY",
  Closed = "CLOSED",
}

export type VideoCallSettings = {
  __typename?: "VideoCallSettings";
  backgroundImage?: Maybe<Scalars["String"]>;
  colors: CustomerScreenColors;
  defaultLanguage: Scalars["String"];
  languages: Array<Scalars["String"]>;
  locale: Array<CustomerScreenLocale>;
  locations: Array<CustomerLocation>;
  logoImage: Scalars["String"];
  name: Scalars["String"];
  redirect: Scalars["Boolean"];
  redirectDelay: Scalars["Int"];
  template: Scalars["String"];
  timeoutDelay: Scalars["Int"];
};

export type CustomerScreenColors = {
  __typename?: "CustomerScreenColors";
  background: Scalars["String"];
  buttonsBackground: Scalars["String"];
  buttonsText: Scalars["String"];
  text: Scalars["String"];
};

export type CustomerScreenLocale = {
  __typename?: "CustomerScreenLocale";
  content: Array<TextByLanguage>;
  type: Scalars["String"];
};

export type TextByLanguage = {
  __typename?: "TextByLanguage";
  language: Scalars["String"];
  value: Scalars["String"];
};

export type CustomerLocation = {
  __typename?: "CustomerLocation";
  id: Scalars["ID"];
  description: Scalars["String"];
  title: Scalars["String"];
  clientSideRotation?: Maybe<Scalars["Float"]>;
  clientSideRotationInputStream?: Maybe<Scalars["Float"]>;
  agentSideRotation?: Maybe<Scalars["Float"]>;
};

export type DefaultLocale = {
  __typename?: "DefaultLocale";
  content: Array<Content>;
  type: TextLocation;
};

export type Content = {
  __typename?: "Content";
  language: Language;
  value: Scalars["String"];
};

export enum Language {
  De = "DE",
  En = "EN",
  Fr = "FR",
  Nl = "NL",
}

/** Translatable strings in the application */
export enum TextLocation {
  Closed = "closed",
  InitiateCall = "initiateCall",
  Intro = "intro",
  Unavailable = "unavailable",
  Welcome = "welcome",
}

export type VideoCall = {
  __typename?: "VideoCall";
  id: Scalars["ID"];
  answeredAt?: Maybe<Scalars["DateTime"]>;
  callAgent?: Maybe<User>;
  createdAt: Scalars["DateTime"];
  customerId: Scalars["String"];
  endedAt?: Maybe<Scalars["DateTime"]>;
  location?: Maybe<Scalars["String"]>;
  inProgress: Scalars["Boolean"];
  roomId?: Maybe<Scalars["String"]>;
  /**
   * Returns duration in seconds. Returns 0 if
   *                       conversation is not answered or finished yet.
   */
  duration: Scalars["Int"];
};

export type VideoCallFilterInput = {
  from?: Maybe<Scalars["DateTime"]>;
  to?: Maybe<Scalars["DateTime"]>;
  search?: Maybe<Scalars["String"]>;
};

export type VideoCallResult = {
  __typename?: "VideoCallResult";
  count: Scalars["Int"];
  items: Array<VideoCall>;
};

export type IncomingCallReportingFilterInput = {
  from?: Maybe<Scalars["DateTime"]>;
  to?: Maybe<Scalars["DateTime"]>;
};

export type IncomingCallReportingResult = {
  __typename?: "IncomingCallReportingResult";
  totalDuration: Scalars["Int"];
  totalCalls: Scalars["Int"];
  durationByCallType: Array<IncomingCallReportingDuration>;
};

export type IncomingCallReportingDuration = {
  __typename?: "IncomingCallReportingDuration";
  type: DayNightCallTypes;
  calls: Scalars["Int"];
  duration: Scalars["Int"];
};

export type OutgoingCallReportingFilterInput = {
  from?: Maybe<Scalars["DateTime"]>;
  to?: Maybe<Scalars["DateTime"]>;
};

export type OutgoingCallReportingResult = {
  __typename?: "OutgoingCallReportingResult";
  totalDuration: Scalars["Int"];
  totalCalls: Scalars["Int"];
  durationByCallType: Array<OutgoingCallReportingDuration>;
};

export type OutgoingCallReportingDuration = {
  __typename?: "OutgoingCallReportingDuration";
  type: DayNightCallTypes;
  calls: Scalars["Int"];
  duration: Scalars["Int"];
};

export enum LangEnum {
  En = "en",
  Fr = "fr",
  De = "de",
  Nl = "nl",
}

export type DownloadResult = {
  __typename?: "DownloadResult";
  content: Scalars["String"];
  contentType: Scalars["String"];
  fileName: Scalars["String"];
};

export type OperationReportingFilterInput = {
  from?: Maybe<Scalars["DateTime"]>;
  to?: Maybe<Scalars["DateTime"]>;
};

export type OperationReportingResult = {
  __typename?: "OperationReportingResult";
  global: GlobalOperationReporting;
  durations: Array<OperationDurationReporting>;
  waitingDurations: Array<OperationWaitingDurationReporting>;
  totalOperationsByHour: Array<TotalOperationReportingByHour>;
};

export type OperationReportingResultDurationsArgs = {
  ranges?: Maybe<Array<OperationRangeInput>>;
};

export type OperationReportingResultWaitingDurationsArgs = {
  ranges?: Maybe<Array<OperationRangeInput>>;
};

export type GlobalOperationReporting = {
  __typename?: "GlobalOperationReporting";
  totalOperations: Scalars["Int"];
  averageDuration: Scalars["Float"];
  averageWaitingDuration: Scalars["Float"];
};

export type OperationRangeInput = {
  from?: Maybe<Scalars["Int"]>;
  to?: Maybe<Scalars["Int"]>;
};

export type OperationDurationReporting = {
  __typename?: "OperationDurationReporting";
  range: OperationRange;
  count: Scalars["Int"];
};

export type OperationRange = {
  __typename?: "OperationRange";
  from?: Maybe<Scalars["Int"]>;
  to?: Maybe<Scalars["Int"]>;
};

export type OperationWaitingDurationReporting = {
  __typename?: "OperationWaitingDurationReporting";
  range: OperationRange;
  count: Scalars["Int"];
};

export type TotalOperationReportingByHour = {
  __typename?: "TotalOperationReportingByHour";
  hour: Scalars["Int"];
  count: Scalars["Int"];
};

export type VideoCallReportingFilterInput = {
  from?: Maybe<Scalars["DateTime"]>;
  to?: Maybe<Scalars["DateTime"]>;
};

export type VideoCallReportingResult = {
  __typename?: "VideoCallReportingResult";
  totalDuration: Scalars["Int"];
};

export type SearchFilterInput = {
  types?: Maybe<Array<SearchTypes>>;
};

/** The different types of search */
export enum SearchTypes {
  Message = "MESSAGE",
  Agentmessage = "AGENTMESSAGE",
  Attachment = "ATTACHMENT",
  CustomerCrmContact = "CUSTOMER_CRM_CONTACT",
  CustomerFixedInstruction = "CUSTOMER_FIXED_INSTRUCTION",
  CustomerTemporaryInstruction = "CUSTOMER_TEMPORARY_INSTRUCTION",
}

export type SearchResult = {
  __typename?: "SearchResult";
  count: Scalars["Int"];
  countByType: Array<SearchTypeCount>;
  items: Array<Search>;
};

export type SearchTypeCount = {
  __typename?: "SearchTypeCount";
  count: Scalars["Int"];
  type: SearchTypes;
};

export type Search = {
  type: SearchTypes;
};

export type StandbyFilter = {
  ids?: Maybe<Array<Scalars["ID"]>>;
};

export type StandbysResult = {
  __typename?: "StandbysResult";
  count: Scalars["Int"];
  items: Array<Standby>;
};

export type Standby = {
  __typename?: "Standby";
  id: Scalars["ID"];
  from: StandbyHours;
  to: StandbyHours;
  notifyChannels?: Maybe<Array<StandbyNotifyChannels>>;
  crmContacts?: Maybe<Array<CustomerCrmContact>>;
};

export type StandbyHours = {
  __typename?: "StandbyHours";
  hours: Scalars["Int"];
  minutes: Scalars["Int"];
};

/** The different notify channels of standby */
export enum StandbyNotifyChannels {
  Sms = "SMS",
  Email = "EMAIL",
}

export type Theme = {
  __typename?: "Theme";
  colors: Colors;
  images: Array<Image>;
};

export type Colors = {
  __typename?: "Colors";
  error: Scalars["String"];
  info: Scalars["String"];
  primary: Scalars["String"];
  primaryDarkShade: Scalars["String"];
  primaryLightShade: Scalars["String"];
  secondary: Scalars["String"];
  secondaryDarkShade: Scalars["String"];
  secondaryLightShade: Scalars["String"];
  success: Scalars["String"];
  warning: Scalars["String"];
};

export type Image = {
  __typename?: "Image";
  type: Scalars["String"];
  url: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  updateCustomer: Customer;
  activateChannel: MutationResult;
  deactivateChannel: MutationResult;
  pairChannel: PairedChannel;
  restoreChannel: MutationResult;
  unpairChannel: MutationResult;
  updateChannel: PairedChannel;
  createRole: Role;
  updateRole: Role;
  deleteRole: Role;
  createUser: User;
  updateUser: User;
  updateCurrentUserLocale: Scalars["Boolean"];
  deleteUser: MutationResult;
  updateUserStatus: UserStatus;
  createCustomerCrmContact: CustomerCrmContact;
  updateCustomerCrmContact: CustomerCrmContact;
  deleteCustomerCrmContact: MutationResult;
  createMeccaConversation: MeccaConversationModel;
  editMeccaConversation: MeccaConversationModel;
  removeMeccaMessageAttachment: Scalars["Boolean"];
  addMeccaMessageAttachment: Scalars["Boolean"];
  updateMeccaConversation: MeccaConversationModel;
  bulkUpdateMeccaConversations: Array<MeccaConversationModel>;
  addMeccaConversationAgentMessage: MutationResult;
  forwardMeccaConversationAgentMessage: MutationResult;
  forwardMeccaConversationsAgent: MutationResult;
  bulkUpdateSocialConversations: Array<SocialConversationModel>;
  updateSocialConversation: SocialConversationModel;
  sendSocialConversationText: MutationResult;
  removeSocialConversationAttachment: Scalars["Boolean"];
  createCustomerAttachment: CustomerAttachment;
  updateCustomerAttachment: CustomerAttachment;
  deleteCustomerAttachment: MutationResult;
  payInvoice: PayInvoiceResult;
  sendContactRequest: MutationResult;
  createCustomerTemporaryInstruction: CustomerTemporaryInstruction;
  updateCustomerTemporaryInstruction: CustomerTemporaryInstruction;
  deleteCustomerTemporaryInstruction: MutationResult;
  initiateVideoCall: VideoCallInfo;
  endVideoCall: MutationResult;
  removeScheduledVideoCall: MutationResult;
  createVideoCallSettings: VideoCallSettings;
  updateVideoCallSettings: VideoCallSettings;
  updateVideoCallSettingsLocation: VideoCallSettings;
  answerVideoCall: VideoCallInfo;
  createStandby: Standby;
  updateStandby: Standby;
  deleteStandby: MutationResult;
  registerWebpush: MutationResult;
};

export type MutationUpdateCustomerArgs = {
  id: Scalars["ID"];
  input: UpdateCustomerInput;
};

export type MutationActivateChannelArgs = {
  id: Scalars["ID"];
};

export type MutationDeactivateChannelArgs = {
  id: Scalars["ID"];
};

export type MutationPairChannelArgs = {
  input: PairChannelInput;
};

export type MutationRestoreChannelArgs = {
  id: Scalars["ID"];
};

export type MutationUnpairChannelArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateChannelArgs = {
  input: PairedChannelUpdateInput;
  id: Scalars["ID"];
};

export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};

export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
  id: Scalars["ID"];
};

export type MutationDeleteRoleArgs = {
  id: Scalars["ID"];
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
  id: Scalars["ID"];
};

export type MutationUpdateCurrentUserLocaleArgs = {
  locale: Scalars["String"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateUserStatusArgs = {
  input: UpdateUserStatusInput;
};

export type MutationCreateCustomerCrmContactArgs = {
  input: CreateCustomerCrmContactInput;
};

export type MutationUpdateCustomerCrmContactArgs = {
  id: Scalars["Int"];
  input: UpdateCustomerCrmContactInput;
};

export type MutationDeleteCustomerCrmContactArgs = {
  id: Scalars["Int"];
};

export type MutationCreateMeccaConversationArgs = {
  input: CreateMeccaConversationInputModel;
};

export type MutationEditMeccaConversationArgs = {
  input: EditMeccaConversationInputModel;
};

export type MutationRemoveMeccaMessageAttachmentArgs = {
  input: RemoveMeccaMessageAttachmentInputModel;
};

export type MutationAddMeccaMessageAttachmentArgs = {
  input: AddMeccaMessageAttachmentInputModel;
};

export type MutationUpdateMeccaConversationArgs = {
  input: UpdateMeccaConversationInputModel;
  id: Scalars["ID"];
};

export type MutationBulkUpdateMeccaConversationsArgs = {
  input: UpdateMeccaConversationInputModel;
  ids: Array<Scalars["ID"]>;
};

export type MutationAddMeccaConversationAgentMessageArgs = {
  input: AddConversationAgentMessage;
  agentMessageId: Scalars["ID"];
  conversationId: Scalars["ID"];
};

export type MutationForwardMeccaConversationAgentMessageArgs = {
  conversationId: Scalars["ID"];
  agentMessageId: Scalars["ID"];
  input: ForwardConversationAgentMessage;
};

export type MutationForwardMeccaConversationsAgentArgs = {
  messagesIds: Array<Scalars["ID"]>;
  input: ForwardConversationAgentMessage;
};

export type MutationBulkUpdateSocialConversationsArgs = {
  input: UpdateSocialConversationInputModel;
  ids: Array<Scalars["ID"]>;
};

export type MutationUpdateSocialConversationArgs = {
  input: UpdateSocialConversationInputModel;
  id: Scalars["ID"];
};

export type MutationSendSocialConversationTextArgs = {
  input: SendConversationTextInput;
  id: Scalars["ID"];
};

export type MutationRemoveSocialConversationAttachmentArgs = {
  input: RemoveSocialMessageAttachmentInputModel;
};

export type MutationCreateCustomerAttachmentArgs = {
  input: CreateCustomerAttachmentInput;
};

export type MutationUpdateCustomerAttachmentArgs = {
  id: Scalars["Int"];
  input: UpdateCustomerAttachmentInput;
};

export type MutationDeleteCustomerAttachmentArgs = {
  id: Scalars["Int"];
};

export type MutationPayInvoiceArgs = {
  id: Scalars["Int"];
};

export type MutationSendContactRequestArgs = {
  input: CreateContactRequestInput;
};

export type MutationCreateCustomerTemporaryInstructionArgs = {
  input: CreateCustomerTemporaryInstructionInput;
};

export type MutationUpdateCustomerTemporaryInstructionArgs = {
  id: Scalars["Int"];
  input: UpdateCustomerTemporaryInstructionInput;
};

export type MutationDeleteCustomerTemporaryInstructionArgs = {
  id: Scalars["Int"];
};

export type MutationInitiateVideoCallArgs = {
  platformId?: Maybe<Scalars["ID"]>;
  customerId?: Maybe<Scalars["ID"]>;
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
  locationId?: Maybe<Scalars["String"]>;
};

export type MutationEndVideoCallArgs = {
  platformId?: Maybe<Scalars["ID"]>;
  customerId?: Maybe<Scalars["ID"]>;
  callId: Scalars["ID"];
};

export type MutationRemoveScheduledVideoCallArgs = {
  platformId?: Maybe<Scalars["ID"]>;
  customerId?: Maybe<Scalars["ID"]>;
  callId: Scalars["ID"];
};

export type MutationCreateVideoCallSettingsArgs = {
  input: VideoCallSettingsInput;
};

export type MutationUpdateVideoCallSettingsArgs = {
  input: VideoCallSettingsInput;
};

export type MutationUpdateVideoCallSettingsLocationArgs = {
  input: UpdateVideoCallSettingsLocationInput;
};

export type MutationAnswerVideoCallArgs = {
  callId: Scalars["ID"];
};

export type MutationCreateStandbyArgs = {
  input: CreateStandbyInput;
};

export type MutationUpdateStandbyArgs = {
  id: Scalars["ID"];
  input: UpdateStandbyInput;
};

export type MutationDeleteStandbyArgs = {
  id: Scalars["ID"];
};

export type MutationRegisterWebpushArgs = {
  input: RegisterWebPushInput;
};

export type UpdateCustomerInput = {
  info: CustomerInfoInput;
};

export type CustomerInfoInput = {
  contactInformationList: Array<CustomerContactInformationInput>;
};

export type CustomerContactInformationInput = {
  type: CustomerContactInformationTypes;
  address?: Maybe<AddressInput>;
  emailAddress?: Maybe<Scalars["String"]>;
  faxNumber?: Maybe<Scalars["String"]>;
  mobilePhoneNumber?: Maybe<Scalars["String"]>;
  phoneNumber1?: Maybe<Scalars["String"]>;
  phoneNumber2?: Maybe<Scalars["String"]>;
  website?: Maybe<Scalars["String"]>;
};

export type AddressInput = {
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  streetNumber?: Maybe<Scalars["String"]>;
  box?: Maybe<Scalars["String"]>;
};

export type MutationResult = {
  __typename?: "MutationResult";
  success: Scalars["Boolean"];
};

export type PairChannelInput = {
  channelIdentifier: Scalars["String"];
  type: ChannelType;
  facebook?: Maybe<PairChannelFacebookInput>;
  twitter?: Maybe<PairChannelTwitterInput>;
  whatsapp?: Maybe<PairChannelWhatsappInput>;
};

export type PairChannelFacebookInput = {
  accessToken: Scalars["String"];
  pageId: Scalars["String"];
};

export type PairChannelTwitterInput = {
  forUserId: Scalars["String"];
};

export type PairChannelWhatsappInput = {
  number: Scalars["String"];
};

export type PairedChannelUpdateInput = {
  channelIdentifier?: Maybe<Scalars["String"]>;
  redirect?: Maybe<Scalars["Boolean"]>;
  redirectDelay?: Maybe<Scalars["Int"]>;
};

export type CreateRoleInput = {
  name: Scalars["String"];
  permissions: Array<Scalars["String"]>;
};

export type UpdateRoleInput = {
  name: Scalars["String"];
  permissions: Array<Scalars["String"]>;
};

export type CreateUserInput = {
  emailAddress: Scalars["String"];
  firstName: Scalars["String"];
  role: UserRole;
  username: Scalars["String"];
  password: Scalars["String"];
  lastName: Scalars["String"];
  customers: Array<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type UpdateUserInput = {
  emailAddress: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  role: UserRole;
  customers: Array<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
  phoneNumber?: Maybe<Scalars["String"]>;
};

export type UpdateUserStatusInput = {
  active?: Maybe<Scalars["Boolean"]>;
};

export type CreateCustomerCrmContactInput = {
  title?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName: Scalars["String"];
  sex?: Maybe<CustomerCrmContactSexes>;
  functionName?: Maybe<Scalars["String"]>;
  addresses?: Maybe<Array<CustomerCrmContactAddressInput>>;
  communications?: Maybe<Array<CustomerCrmContactCommunicationInput>>;
  groups?: Maybe<Array<CustomerCrmContactGroupRelationInput>>;
};

export type CustomerCrmContactAddressInput = {
  id?: Maybe<Scalars["Int"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  address?: Maybe<Scalars["String"]>;
  description: Scalars["String"];
};

export type CustomerCrmContactCommunicationInput = {
  typeId: Scalars["Int"];
  sequence?: Maybe<Scalars["Int"]>;
  value?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  forwardStatus?: Maybe<CustomerCrmContactCommunicationStatusses>;
  forwardCondition?: Maybe<Scalars["String"]>;
  passThroughStatus?: Maybe<CustomerCrmContactCommunicationStatusses>;
  passThroughCondition?: Maybe<Scalars["String"]>;
};

export type CustomerCrmContactGroupRelationInput = {
  groupId: Scalars["Int"];
  subGroupId: Scalars["Int"];
};

export type UpdateCustomerCrmContactInput = {
  title?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  sex?: Maybe<CustomerCrmContactSexes>;
  functionName?: Maybe<Scalars["String"]>;
  addresses?: Maybe<Array<CustomerCrmContactAddressInput>>;
  communications?: Maybe<Array<CustomerCrmContactCommunicationInput>>;
  groups?: Maybe<Array<CustomerCrmContactGroupRelationInput>>;
};

export type CreateMeccaConversationInputModel = {
  channelType: ChannelType;
  status: ConversationStatus;
  attachments?: Maybe<Array<Scalars["Upload"]>>;
  content?: Maybe<Scalars["String"]>;
  read?: Maybe<Scalars["Boolean"]>;
  emails?: Maybe<Array<Scalars["String"]>>;
  phones?: Maybe<Array<Scalars["String"]>>;
  structuredType?: Maybe<MeccaConversationStructuredTypeValueInput>;
  notify?: Maybe<Scalars["Boolean"]>;
};

export type MeccaConversationStructuredTypeValueInput = {
  name: Scalars["String"];
  fieldValues: Array<MeccaConversationStructuredTypeValueFieldValueInput>;
};

export type MeccaConversationStructuredTypeValueFieldValueInput = {
  sequence: Scalars["Float"];
  value?: Maybe<Scalars["String"]>;
};

export type EditMeccaConversationInputModel = {
  messageId: Scalars["ID"];
  content?: Maybe<Scalars["String"]>;
  emails?: Maybe<Array<Scalars["String"]>>;
  phones?: Maybe<Array<Scalars["String"]>>;
  structuredType?: Maybe<MeccaConversationStructuredTypeValueInput>;
};

export type RemoveMeccaMessageAttachmentInputModel = {
  attachmentId: Scalars["Float"];
  messageId: Scalars["Float"];
};

export type AddMeccaMessageAttachmentInputModel = {
  messageId: Scalars["Float"];
  attachments?: Maybe<Array<Scalars["Upload"]>>;
};

export type UpdateMeccaConversationInputModel = {
  flagged?: Maybe<Scalars["Boolean"]>;
  priority?: Maybe<Scalars["Boolean"]>;
  read?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<ConversationStatus>;
};

export type AddConversationAgentMessage = {
  attachments?: Maybe<Array<Scalars["Upload"]>>;
  content?: Maybe<Scalars["String"]>;
  fieldValues: Array<ConversationStructuredTypeValueFieldValueInput>;
};

export type ConversationStructuredTypeValueFieldValueInput = {
  sequence: Scalars["Float"];
  value: Scalars["String"];
};

export type ForwardConversationAgentMessage = {
  emails?: Maybe<Array<Scalars["String"]>>;
  phones?: Maybe<Array<Scalars["String"]>>;
};

export type UpdateSocialConversationInputModel = {
  archived?: Maybe<Scalars["Boolean"]>;
  flagged?: Maybe<Scalars["Boolean"]>;
  priority?: Maybe<Scalars["Boolean"]>;
  read?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<ConversationStatus>;
};

export type SendConversationTextInput = {
  attachments?: Maybe<Array<Scalars["Upload"]>>;
  content?: Maybe<Scalars["String"]>;
  messageTime: Scalars["DateTime"];
};

export type RemoveSocialMessageAttachmentInputModel = {
  attachmentUrl: Scalars["String"];
  conversationId: Scalars["String"];
};

export type CreateCustomerAttachmentInput = {
  name: Scalars["String"];
  validFrom: Scalars["DateTime"];
  validTill: Scalars["DateTime"];
  attachment: Scalars["Upload"];
};

export type UpdateCustomerAttachmentInput = {
  name?: Maybe<Scalars["String"]>;
  validFrom?: Maybe<Scalars["DateTime"]>;
  validTill?: Maybe<Scalars["DateTime"]>;
  attachment?: Maybe<Scalars["Upload"]>;
};

export type PayInvoiceResult = {
  __typename?: "PayInvoiceResult";
  redirectUrl?: Maybe<Scalars["String"]>;
};

export type CreateContactRequestInput = {
  name: Scalars["String"];
  email: Scalars["String"];
  subject: Scalars["String"];
  message: Scalars["String"];
};

export type CreateCustomerTemporaryInstructionInput = {
  name: Scalars["String"];
  content: Scalars["String"];
  validFrom: Scalars["DateTime"];
  validUntill: Scalars["DateTime"];
};

export type UpdateCustomerTemporaryInstructionInput = {
  name?: Maybe<Scalars["String"]>;
  content?: Maybe<Scalars["String"]>;
  validFrom?: Maybe<Scalars["DateTime"]>;
  validUntill?: Maybe<Scalars["DateTime"]>;
};

export type VideoCallInfo = {
  __typename?: "VideoCallInfo";
  callId: Scalars["ID"];
  customerName?: Maybe<Scalars["String"]>;
  location?: Maybe<Location>;
  token: Scalars["String"];
};

export type Location = {
  __typename?: "Location";
  id?: Maybe<Scalars["ID"]>;
  address?: Maybe<LocationAddress>;
  customerId?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
  title?: Maybe<Scalars["String"]>;
  clientSideRotation?: Maybe<Scalars["Float"]>;
  clientSideRotationInputStream?: Maybe<Scalars["Float"]>;
  agentSideRotation?: Maybe<Scalars["Float"]>;
};

export type LocationAddress = {
  __typename?: "LocationAddress";
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
  streetNumber?: Maybe<Scalars["String"]>;
};

export type VideoCallSettingsInput = {
  name?: Maybe<Scalars["String"]>;
  languages?: Maybe<Array<Scalars["String"]>>;
  defaultLanguage?: Maybe<Scalars["String"]>;
  logoImage?: Maybe<Scalars["String"]>;
  backgroundImage?: Maybe<Scalars["String"]>;
  colors?: Maybe<CustomerScreenColorsInput>;
  locale?: Maybe<Array<CustomerScreenLocaleInput>>;
  locations?: Maybe<Array<CustomerLocationInput>>;
  redirectDelay: Scalars["Int"];
  timeoutDelay?: Maybe<Scalars["Int"]>;
  template?: Maybe<Scalars["String"]>;
  redirect: Scalars["Boolean"];
};

export type CustomerScreenColorsInput = {
  buttonsBackground?: Maybe<Scalars["String"]>;
  buttonsText?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  background?: Maybe<Scalars["String"]>;
};

export type CustomerScreenLocaleInput = {
  content?: Maybe<Array<TextByLanguageInput>>;
  type?: Maybe<Scalars["String"]>;
};

export type TextByLanguageInput = {
  language?: Maybe<Scalars["String"]>;
  value?: Maybe<Scalars["String"]>;
};

export type CustomerLocationInput = {
  id?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  clientSideRotation?: Maybe<Scalars["Float"]>;
  agentSideRotation?: Maybe<Scalars["Float"]>;
};

export type UpdateVideoCallSettingsLocationInput = {
  id?: Maybe<Scalars["String"]>;
  agentSideRotation?: Maybe<Scalars["Float"]>;
  clientSideRotation?: Maybe<Scalars["Float"]>;
  clientSideRotationInputStream?: Maybe<Scalars["Float"]>;
};

export type CreateStandbyInput = {
  from: StandbyHoursInput;
  to: StandbyHoursInput;
  crmContactIds?: Maybe<Array<Scalars["Int"]>>;
  notifyChannels?: Maybe<Array<StandbyNotifyChannels>>;
};

export type StandbyHoursInput = {
  hours: Scalars["Int"];
  minutes: Scalars["Int"];
};

export type UpdateStandbyInput = {
  from?: Maybe<StandbyHoursInput>;
  to?: Maybe<StandbyHoursInput>;
  crmContactIds?: Maybe<Array<Scalars["Int"]>>;
  notifyChannels?: Maybe<Array<StandbyNotifyChannels>>;
};

export type RegisterWebPushInput = {
  endpoint: Scalars["String"];
  keys: WebPushKeysInput;
};

export type WebPushKeysInput = {
  p256dh: Scalars["String"];
  auth: Scalars["String"];
};

export type Subscription = {
  __typename?: "Subscription";
  userStatusUpdated: UserStatus;
  meccaConversationCreated: MeccaTextConversationModel;
  meccaConversationUpdated: MeccaTextConversationModel;
  meccaConversationBulkUpdated: Array<MeccaTextConversationModel>;
  meccaConversationTextAdded: Message;
  socialConversationCreated: SocialConversationModel;
  SocialConversationUpdated: SocialConversationModel;
  SocialConversationBulkUpdated: Array<SocialConversationModel>;
  SocialNewReceivedMessage: Message;
  callNotification: VideoCallNotification;
  settingsUpdated: UpdateVideoCallSettingsNotification;
};

export type SubscriptionSettingsUpdatedArgs = {
  platformId: Scalars["String"];
  customerId: Scalars["String"];
};

export type MeccaTextConversationModel = MeccaConversationModel & {
  __typename?: "MeccaTextConversationModel";
  id: Scalars["ID"];
  channelType: ChannelType;
  createdAt: Scalars["DateTime"];
  createdBy: User;
  lastUpdatedAt: Scalars["DateTime"];
  events: Array<EventModel>;
  flagged?: Maybe<Scalars["Boolean"]>;
  participants: Array<MeccaConversationParticipant>;
  priority: Scalars["Float"];
  lastMessageTime: Scalars["DateTime"];
  read: Scalars["Boolean"];
  status: ConversationStatus;
  related: Array<MeccaConversationModel>;
  preview?: Maybe<MeccaTextConversationPreviewModel>;
  multipleResponsesTill?: Maybe<Scalars["DateTime"]>;
  canSendResponse?: Maybe<Scalars["Boolean"]>;
  channel?: Maybe<PairedChannel>;
  channelsActive: Scalars["Boolean"];
  messages: Array<Message>;
  agentMessages: Array<AgentMessageResult>;
};

export type MeccaTextConversationPreviewModel = {
  type: TextConversationPreviewTypes;
};

export type AgentMessageResult = {
  __typename?: "AgentMessageResult";
  id: Scalars["ID"];
  messages: Array<AgentMessage>;
  attachments: Array<Attachment>;
  structuredMessage?: Maybe<AgentMessageStructuredMessage>;
  status: AgentMessageStatus;
};

export type AgentMessage = {
  __typename?: "AgentMessage";
  id: Scalars["ID"];
  channelType: ChannelType;
  content?: Maybe<Scalars["String"]>;
  conversationId: Scalars["ID"];
  destination: Scalars["String"];
  external?: Maybe<Scalars["Boolean"]>;
  messageTime: Scalars["DateTime"];
  sender: User;
  serviceId: Scalars["String"];
  source: Scalars["String"];
};

export type AgentMessageStructuredMessage = {
  __typename?: "AgentMessageStructuredMessage";
  name: Scalars["String"];
  fields: Array<ConversationStructuredTypeField>;
  values: Array<AgentMessageStructuredMessageValue>;
};

export type AgentMessageStructuredMessageValue = {
  __typename?: "AgentMessageStructuredMessageValue";
  sequence: Scalars["Float"];
  value?: Maybe<Scalars["String"]>;
};

/** The different statusses of agent message */
export enum AgentMessageStatus {
  Normal = "NORMAL",
  Important = "IMPORTANT",
  Urgent = "URGENT",
  VeryUrgent = "VERY_URGENT",
  CallbackFromCaller = "CALLBACK_FROM_CALLER",
  CallbackToCaller = "CALLBACK_TO_CALLER",
  MailBack = "MAIL_BACK",
}

export type VideoCallNotification = {
  __typename?: "VideoCallNotification";
  callId: Scalars["String"];
  customerId: Scalars["String"];
  removeCall?: Maybe<Scalars["Boolean"]>;
};

export type UpdateVideoCallSettingsNotification = {
  __typename?: "UpdateVideoCallSettingsNotification";
  customerId: Scalars["String"];
  platformId: Scalars["String"];
  locations: Array<CustomerLocation>;
};

export type Conversation = {
  id: Scalars["ID"];
  archived: Scalars["Boolean"];
  channelType: ChannelType;
  createdAt: Scalars["DateTime"];
  createdBy: User;
  lastUpdatedAt: Scalars["DateTime"];
  events: Array<EventModel>;
  flagged: Scalars["Boolean"];
  participants: Array<ConversationParticipant>;
  priority: Scalars["Float"];
  read: Scalars["Boolean"];
  status: ConversationStatus;
  related: Array<Conversation>;
};

export type ConversationParticipant = {
  __typename?: "ConversationParticipant";
  channel: ChannelType;
  channelId: Scalars["ID"];
  destination: Scalars["String"];
  source: Scalars["String"];
};

export type TextConversationPreview = {
  type: TextConversationPreviewTypes;
};

export type PairedChannelBlank = PairedChannel & {
  __typename?: "PairedChannelBlank";
  id: Scalars["ID"];
  account: Scalars["ID"];
  active: Scalars["Boolean"];
  channelIdentifier?: Maybe<Scalars["String"]>;
  deleted: Scalars["Boolean"];
  redirect?: Maybe<Scalars["Boolean"]>;
  redirectDelay?: Maybe<Scalars["Int"]>;
  type: ChannelType;
};

export type PairedChannelFacebook = PairedChannel & {
  __typename?: "PairedChannelFacebook";
  id: Scalars["ID"];
  account: Scalars["ID"];
  active: Scalars["Boolean"];
  channelIdentifier?: Maybe<Scalars["String"]>;
  deleted: Scalars["Boolean"];
  redirect?: Maybe<Scalars["Boolean"]>;
  redirectDelay?: Maybe<Scalars["Int"]>;
  type: ChannelType;
  pageId: Scalars["String"];
};

export type PairedChannelTwitter = PairedChannel & {
  __typename?: "PairedChannelTwitter";
  id: Scalars["ID"];
  account: Scalars["ID"];
  active: Scalars["Boolean"];
  channelIdentifier?: Maybe<Scalars["String"]>;
  deleted: Scalars["Boolean"];
  redirect?: Maybe<Scalars["Boolean"]>;
  redirectDelay?: Maybe<Scalars["Int"]>;
  type: ChannelType;
  forUserId: Scalars["String"];
};

export type PairedChannelVideo = PairedChannel & {
  __typename?: "PairedChannelVideo";
  id: Scalars["ID"];
  account: Scalars["ID"];
  active: Scalars["Boolean"];
  channelIdentifier?: Maybe<Scalars["String"]>;
  deleted: Scalars["Boolean"];
  redirect?: Maybe<Scalars["Boolean"]>;
  redirectDelay?: Maybe<Scalars["Int"]>;
  type: ChannelType;
};

export type PairedChannelWhatsapp = PairedChannel & {
  __typename?: "PairedChannelWhatsapp";
  id: Scalars["ID"];
  account: Scalars["ID"];
  active: Scalars["Boolean"];
  channelIdentifier?: Maybe<Scalars["String"]>;
  deleted: Scalars["Boolean"];
  redirect?: Maybe<Scalars["Boolean"]>;
  redirectDelay?: Maybe<Scalars["Int"]>;
  type: ChannelType;
  number: Scalars["String"];
};

export type ConversationCreatedEvent = EventModel & {
  __typename?: "ConversationCreatedEvent";
  eventTime: Scalars["DateTime"];
  type: EventTypes;
  source?: Maybe<Scalars["String"]>;
};

export type ConversationUpdatedEvent = EventModel & {
  __typename?: "ConversationUpdatedEvent";
  eventTime: Scalars["DateTime"];
  type: EventTypes;
  source?: Maybe<Scalars["String"]>;
};

export type ConversationForwardedEvent = EventModel & {
  __typename?: "ConversationForwardedEvent";
  eventTime: Scalars["DateTime"];
  type: EventTypes;
};

export type ConversationReadedEvent = EventModel & {
  __typename?: "ConversationReadedEvent";
  eventTime: Scalars["DateTime"];
  type: EventTypes;
};

export type ConversationFlaggedEvent = EventModel & {
  __typename?: "ConversationFlaggedEvent";
  eventTime: Scalars["DateTime"];
  type: EventTypes;
};

export type ConversationUnflaggedEvent = EventModel & {
  __typename?: "ConversationUnflaggedEvent";
  eventTime: Scalars["DateTime"];
  type: EventTypes;
};

export type ConversationArchivedEvent = EventModel & {
  __typename?: "ConversationArchivedEvent";
  eventTime: Scalars["DateTime"];
  type: EventTypes;
};

export type ConversationClosedEvent = EventModel & {
  __typename?: "ConversationClosedEvent";
  eventTime: Scalars["DateTime"];
  type: EventTypes;
};

export type ConversationStatusChangedEvent = EventModel & {
  __typename?: "ConversationStatusChangedEvent";
  eventTime: Scalars["DateTime"];
  type: EventTypes;
  newStatus: ConversationStatus;
};

export type ConversationPriorityChangedEvent = EventModel & {
  __typename?: "ConversationPriorityChangedEvent";
  eventTime: Scalars["DateTime"];
  type: EventTypes;
  newPriority: Scalars["Float"];
};

export type BlankConversation = Conversation & {
  __typename?: "BlankConversation";
  id: Scalars["ID"];
  archived: Scalars["Boolean"];
  channelType: ChannelType;
  createdAt: Scalars["DateTime"];
  createdBy: User;
  lastUpdatedAt: Scalars["DateTime"];
  events: Array<EventModel>;
  flagged: Scalars["Boolean"];
  participants: Array<ConversationParticipant>;
  priority: Scalars["Float"];
  read: Scalars["Boolean"];
  status: ConversationStatus;
  related: Array<Conversation>;
};

export type TextConversationPreviewTextAndAttachments = TextConversationPreview & {
  __typename?: "TextConversationPreviewTextAndAttachments";
  type: TextConversationPreviewTypes;
  messageTime: Scalars["DateTime"];
  content?: Maybe<Scalars["String"]>;
  attachments?: Maybe<Array<Attachment>>;
};

export type TextConversationPreviewTextAndAttachmentsWithStructuredMessage = TextConversationPreview & {
  __typename?: "TextConversationPreviewTextAndAttachmentsWithStructuredMessage";
  type: TextConversationPreviewTypes;
  messageTime: Scalars["DateTime"];
  content?: Maybe<Scalars["String"]>;
  attachments?: Maybe<Array<Attachment>>;
  structuredMessage?: Maybe<AgentMessageStructuredMessage>;
};

export type TextConversation = Conversation & {
  __typename?: "TextConversation";
  id: Scalars["ID"];
  archived: Scalars["Boolean"];
  channelType: ChannelType;
  createdAt: Scalars["DateTime"];
  createdBy: User;
  lastUpdatedAt: Scalars["DateTime"];
  events: Array<EventModel>;
  flagged: Scalars["Boolean"];
  participants: Array<ConversationParticipant>;
  priority: Scalars["Float"];
  read: Scalars["Boolean"];
  status: ConversationStatus;
  related: Array<Conversation>;
  preview?: Maybe<TextConversationPreview>;
  multipleResponsesTill?: Maybe<Scalars["DateTime"]>;
  canSendResponse?: Maybe<Scalars["Boolean"]>;
};

export type VideoConversation = Conversation & {
  __typename?: "VideoConversation";
  id: Scalars["ID"];
  archived: Scalars["Boolean"];
  channelType: ChannelType;
  createdAt: Scalars["DateTime"];
  createdBy: User;
  lastUpdatedAt: Scalars["DateTime"];
  events: Array<EventModel>;
  flagged: Scalars["Boolean"];
  participants: Array<ConversationParticipant>;
  priority: Scalars["Float"];
  read: Scalars["Boolean"];
  status: ConversationStatus;
  related: Array<Conversation>;
  callId: Scalars["String"];
  redirected: Scalars["Boolean"];
  videoUrl: Scalars["String"];
};

export type MeccaBlankConversationModel = MeccaConversationModel & {
  __typename?: "MeccaBlankConversationModel";
  id: Scalars["ID"];
  channelType: ChannelType;
  createdAt: Scalars["DateTime"];
  createdBy: User;
  lastUpdatedAt: Scalars["DateTime"];
  events: Array<EventModel>;
  flagged?: Maybe<Scalars["Boolean"]>;
  participants: Array<MeccaConversationParticipant>;
  priority: Scalars["Float"];
  lastMessageTime: Scalars["DateTime"];
  read: Scalars["Boolean"];
  status: ConversationStatus;
  related: Array<MeccaConversationModel>;
  channel?: Maybe<PairedChannel>;
  channelsActive: Scalars["Boolean"];
};

export type MeccaTextConversationPreviewModelTextAndAttachments = MeccaTextConversationPreviewModel & {
  __typename?: "MeccaTextConversationPreviewModelTextAndAttachments";
  type: TextConversationPreviewTypes;
  messageTime: Scalars["DateTime"];
  content?: Maybe<Scalars["String"]>;
  attachments?: Maybe<Array<Attachment>>;
};

export type MeccaTextConversationPreviewModelTextAndAttachmentsWithStructuredMessage = MeccaTextConversationPreviewModel & {
  __typename?: "MeccaTextConversationPreviewModelTextAndAttachmentsWithStructuredMessage";
  type: TextConversationPreviewTypes;
  messageTime: Scalars["DateTime"];
  content?: Maybe<Scalars["String"]>;
  attachments?: Maybe<Array<Attachment>>;
  structuredMessage?: Maybe<AgentMessageStructuredMessage>;
};

export type MeccaVideoConversationModel = MeccaConversationModel & {
  __typename?: "MeccaVideoConversationModel";
  id: Scalars["ID"];
  channelType: ChannelType;
  createdAt: Scalars["DateTime"];
  createdBy: User;
  lastUpdatedAt: Scalars["DateTime"];
  events: Array<EventModel>;
  flagged?: Maybe<Scalars["Boolean"]>;
  participants: Array<MeccaConversationParticipant>;
  priority: Scalars["Float"];
  lastMessageTime: Scalars["DateTime"];
  read: Scalars["Boolean"];
  status: ConversationStatus;
  related: Array<MeccaConversationModel>;
  callId: Scalars["String"];
  redirected: Scalars["Boolean"];
  videoUrl: Scalars["String"];
};

export type NotificationInvoice = Notification & {
  __typename?: "NotificationInvoice";
  date: Scalars["DateTime"];
  type: NotificationTypes;
  amount: Scalars["Float"];
  invoice: Invoice;
};

export type NotificationConversation = Notification & {
  __typename?: "NotificationConversation";
  date: Scalars["DateTime"];
  type: NotificationTypes;
  status: ConversationStatus;
  conversationId: Scalars["String"];
  conversation: SocialConversationModel;
};

export type SearchMessage = Search & {
  __typename?: "SearchMessage";
  type: SearchTypes;
  message?: Maybe<Message>;
};

export type SearchAgentMessage = Search & {
  __typename?: "SearchAgentMessage";
  type: SearchTypes;
  agentMessage?: Maybe<AgentMessage>;
};

export type SearchAttachment = Search & {
  __typename?: "SearchAttachment";
  type: SearchTypes;
  attachment?: Maybe<Attachment>;
};

export type SearchCustomerCrmContact = Search & {
  __typename?: "SearchCustomerCrmContact";
  type: SearchTypes;
  customerCrmContact?: Maybe<CustomerCrmContact>;
};

export type SearchCustomerFixedInstruction = Search & {
  __typename?: "SearchCustomerFixedInstruction";
  type: SearchTypes;
  customerFixedInstruction?: Maybe<CustomerFixedInstruction>;
};

export type SearchCustomerTemporaryInstruction = Search & {
  __typename?: "SearchCustomerTemporaryInstruction";
  type: SearchTypes;
  customerTemporaryInstruction?: Maybe<CustomerTemporaryInstruction>;
};

export type GetVideoCallSettingsQueryVariables = Exact<{
  platformId: Scalars["ID"];
  customerId: Scalars["ID"];
}>;

export type GetVideoCallSettingsQuery = { __typename?: "Query" } & {
  videoCallSettings?: Maybe<
    { __typename?: "VideoCallSettings" } & Pick<
      VideoCallSettings,
      "backgroundImage" | "defaultLanguage" | "languages" | "logoImage" | "name" | "redirect" | "redirectDelay" | "template" | "timeoutDelay"
    > & {
        colors: { __typename?: "CustomerScreenColors" } & Pick<CustomerScreenColors, "background" | "buttonsBackground" | "buttonsText" | "text">;
        locale: Array<
          { __typename?: "CustomerScreenLocale" } & Pick<CustomerScreenLocale, "type"> & {
              content: Array<{ __typename?: "TextByLanguage" } & Pick<TextByLanguage, "language" | "value">>;
            }
        >;
        locations: Array<
          { __typename?: "CustomerLocation" } & Pick<
            CustomerLocation,
            "id" | "description" | "title" | "clientSideRotation" | "clientSideRotationInputStream" | "agentSideRotation"
          >
        >;
      }
  >;
};

export type SettingsUpdatedSubscriptionVariables = Exact<{
  customerId: Scalars["String"];
  platformId: Scalars["String"];
}>;

export type SettingsUpdatedSubscription = { __typename?: "Subscription" } & {
  settingsUpdated: { __typename?: "UpdateVideoCallSettingsNotification" } & Pick<UpdateVideoCallSettingsNotification, "customerId" | "platformId"> & {
      locations: Array<
        { __typename?: "CustomerLocation" } & Pick<
          CustomerLocation,
          "id" | "title" | "description" | "agentSideRotation" | "clientSideRotation" | "clientSideRotationInputStream"
        >
      >;
    };
};

export type EndVideoCallMutationVariables = Exact<{
  platformId: Scalars["ID"];
  customerId: Scalars["ID"];
  callId: Scalars["ID"];
}>;

export type EndVideoCallMutation = { __typename?: "Mutation" } & {
  endVideoCall: { __typename?: "MutationResult" } & Pick<MutationResult, "success">;
};

export type AgentsStatusQueryVariables = Exact<{
  platformId: Scalars["ID"];
  customerId: Scalars["ID"];
}>;

export type AgentsStatusQuery = { __typename?: "Query" } & {
  agentsStatus: { __typename?: "AgentsStatusResponse" } & Pick<AgentsStatusResponse, "agentsStatus">;
};

export type InitiateVideoCallMutationVariables = Exact<{
  platformId: Scalars["ID"];
  customerId: Scalars["ID"];
  lat?: Maybe<Scalars["Float"]>;
  lng?: Maybe<Scalars["Float"]>;
  locationId?: Maybe<Scalars["String"]>;
}>;

export type InitiateVideoCallMutation = { __typename?: "Mutation" } & {
  initiateVideoCall: { __typename?: "VideoCallInfo" } & Pick<VideoCallInfo, "token" | "callId">;
};

export type RemoveScheduledVideoCallMutationVariables = Exact<{
  platformId: Scalars["ID"];
  customerId: Scalars["ID"];
  callId: Scalars["ID"];
}>;

export type RemoveScheduledVideoCallMutation = { __typename?: "Mutation" } & {
  removeScheduledVideoCall: { __typename?: "MutationResult" } & Pick<MutationResult, "success">;
};

export const GetVideoCallSettingsDocument = gql`
  query getVideoCallSettings($platformId: ID!, $customerId: ID!) {
    videoCallSettings(platformId: $platformId, customerId: $customerId) {
      backgroundImage
      colors {
        background
        buttonsBackground
        buttonsText
        text
      }
      defaultLanguage
      languages
      locale {
        content {
          language
          value
        }
        type
      }
      locations {
        id
        description
        title
        clientSideRotation
        clientSideRotationInputStream
        agentSideRotation
      }
      logoImage
      name
      redirect
      redirectDelay
      template
      timeoutDelay
    }
  }
`;
export type GetVideoCallSettingsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<GetVideoCallSettingsQuery, GetVideoCallSettingsQueryVariables>,
  "query"
> &
  ({ variables: GetVideoCallSettingsQueryVariables; skip?: boolean } | { skip: boolean });

export const GetVideoCallSettingsComponent = (props: GetVideoCallSettingsComponentProps) => (
  <ApolloReactComponents.Query<GetVideoCallSettingsQuery, GetVideoCallSettingsQueryVariables> query={GetVideoCallSettingsDocument} {...props} />
);

export type GetVideoCallSettingsProps<TChildProps = {}, TDataName extends string = "data"> = {
  [key in TDataName]: ApolloReactHoc.DataValue<GetVideoCallSettingsQuery, GetVideoCallSettingsQueryVariables>;
} &
  TChildProps;
export function withGetVideoCallSettings<TProps, TChildProps = {}, TDataName extends string = "data">(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    GetVideoCallSettingsQuery,
    GetVideoCallSettingsQueryVariables,
    GetVideoCallSettingsProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    GetVideoCallSettingsQuery,
    GetVideoCallSettingsQueryVariables,
    GetVideoCallSettingsProps<TChildProps, TDataName>
  >(GetVideoCallSettingsDocument, {
    alias: "getVideoCallSettings",
    ...operationOptions,
  });
}

/**
 * __useGetVideoCallSettingsQuery__
 *
 * To run a query within a React component, call `useGetVideoCallSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoCallSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoCallSettingsQuery({
 *   variables: {
 *      platformId: // value for 'platformId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetVideoCallSettingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetVideoCallSettingsQuery, GetVideoCallSettingsQueryVariables>
) {
  return ApolloReactHooks.useQuery<GetVideoCallSettingsQuery, GetVideoCallSettingsQueryVariables>(GetVideoCallSettingsDocument, baseOptions);
}
export function useGetVideoCallSettingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetVideoCallSettingsQuery, GetVideoCallSettingsQueryVariables>
) {
  return ApolloReactHooks.useLazyQuery<GetVideoCallSettingsQuery, GetVideoCallSettingsQueryVariables>(GetVideoCallSettingsDocument, baseOptions);
}
export type GetVideoCallSettingsQueryHookResult = ReturnType<typeof useGetVideoCallSettingsQuery>;
export type GetVideoCallSettingsLazyQueryHookResult = ReturnType<typeof useGetVideoCallSettingsLazyQuery>;
export type GetVideoCallSettingsQueryResult = ApolloReactCommon.QueryResult<GetVideoCallSettingsQuery, GetVideoCallSettingsQueryVariables>;
export const SettingsUpdatedDocument = gql`
  subscription settingsUpdated($customerId: String!, $platformId: String!) {
    settingsUpdated(customerId: $customerId, platformId: $platformId) {
      customerId
      platformId
      locations {
        id
        title
        description
        agentSideRotation
        clientSideRotation
        clientSideRotationInputStream
      }
    }
  }
`;
export type SettingsUpdatedComponentProps = Omit<
  ApolloReactComponents.SubscriptionComponentOptions<SettingsUpdatedSubscription, SettingsUpdatedSubscriptionVariables>,
  "subscription"
>;

export const SettingsUpdatedComponent = (props: SettingsUpdatedComponentProps) => (
  <ApolloReactComponents.Subscription<SettingsUpdatedSubscription, SettingsUpdatedSubscriptionVariables>
    subscription={SettingsUpdatedDocument}
    {...props}
  />
);

export type SettingsUpdatedProps<TChildProps = {}, TDataName extends string = "data"> = {
  [key in TDataName]: ApolloReactHoc.DataValue<SettingsUpdatedSubscription, SettingsUpdatedSubscriptionVariables>;
} &
  TChildProps;
export function withSettingsUpdated<TProps, TChildProps = {}, TDataName extends string = "data">(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    SettingsUpdatedSubscription,
    SettingsUpdatedSubscriptionVariables,
    SettingsUpdatedProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withSubscription<
    TProps,
    SettingsUpdatedSubscription,
    SettingsUpdatedSubscriptionVariables,
    SettingsUpdatedProps<TChildProps, TDataName>
  >(SettingsUpdatedDocument, {
    alias: "settingsUpdated",
    ...operationOptions,
  });
}

/**
 * __useSettingsUpdatedSubscription__
 *
 * To run a query within a React component, call `useSettingsUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSettingsUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsUpdatedSubscription({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      platformId: // value for 'platformId'
 *   },
 * });
 */
export function useSettingsUpdatedSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<SettingsUpdatedSubscription, SettingsUpdatedSubscriptionVariables>
) {
  return ApolloReactHooks.useSubscription<SettingsUpdatedSubscription, SettingsUpdatedSubscriptionVariables>(SettingsUpdatedDocument, baseOptions);
}
export type SettingsUpdatedSubscriptionHookResult = ReturnType<typeof useSettingsUpdatedSubscription>;
export type SettingsUpdatedSubscriptionResult = ApolloReactCommon.SubscriptionResult<SettingsUpdatedSubscription>;
export const EndVideoCallDocument = gql`
  mutation endVideoCall($platformId: ID!, $customerId: ID!, $callId: ID!) {
    endVideoCall(platformId: $platformId, customerId: $customerId, callId: $callId) {
      success
    }
  }
`;
export type EndVideoCallMutationFn = ApolloReactCommon.MutationFunction<EndVideoCallMutation, EndVideoCallMutationVariables>;
export type EndVideoCallComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<EndVideoCallMutation, EndVideoCallMutationVariables>,
  "mutation"
>;

export const EndVideoCallComponent = (props: EndVideoCallComponentProps) => (
  <ApolloReactComponents.Mutation<EndVideoCallMutation, EndVideoCallMutationVariables> mutation={EndVideoCallDocument} {...props} />
);

export type EndVideoCallProps<TChildProps = {}, TDataName extends string = "mutate"> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<EndVideoCallMutation, EndVideoCallMutationVariables>;
} &
  TChildProps;
export function withEndVideoCall<TProps, TChildProps = {}, TDataName extends string = "mutate">(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    EndVideoCallMutation,
    EndVideoCallMutationVariables,
    EndVideoCallProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<TProps, EndVideoCallMutation, EndVideoCallMutationVariables, EndVideoCallProps<TChildProps, TDataName>>(
    EndVideoCallDocument,
    {
      alias: "endVideoCall",
      ...operationOptions,
    }
  );
}

/**
 * __useEndVideoCallMutation__
 *
 * To run a mutation, you first call `useEndVideoCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndVideoCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endVideoCallMutation, { data, loading, error }] = useEndVideoCallMutation({
 *   variables: {
 *      platformId: // value for 'platformId'
 *      customerId: // value for 'customerId'
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useEndVideoCallMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EndVideoCallMutation, EndVideoCallMutationVariables>) {
  return ApolloReactHooks.useMutation<EndVideoCallMutation, EndVideoCallMutationVariables>(EndVideoCallDocument, baseOptions);
}
export type EndVideoCallMutationHookResult = ReturnType<typeof useEndVideoCallMutation>;
export type EndVideoCallMutationResult = ApolloReactCommon.MutationResult<EndVideoCallMutation>;
export type EndVideoCallMutationOptions = ApolloReactCommon.BaseMutationOptions<EndVideoCallMutation, EndVideoCallMutationVariables>;
export const AgentsStatusDocument = gql`
  query agentsStatus($platformId: ID!, $customerId: ID!) {
    agentsStatus(platformId: $platformId, customerId: $customerId) {
      agentsStatus
    }
  }
`;
export type AgentsStatusComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AgentsStatusQuery, AgentsStatusQueryVariables>, "query"> &
  ({ variables: AgentsStatusQueryVariables; skip?: boolean } | { skip: boolean });

export const AgentsStatusComponent = (props: AgentsStatusComponentProps) => (
  <ApolloReactComponents.Query<AgentsStatusQuery, AgentsStatusQueryVariables> query={AgentsStatusDocument} {...props} />
);

export type AgentsStatusProps<TChildProps = {}, TDataName extends string = "data"> = {
  [key in TDataName]: ApolloReactHoc.DataValue<AgentsStatusQuery, AgentsStatusQueryVariables>;
} &
  TChildProps;
export function withAgentsStatus<TProps, TChildProps = {}, TDataName extends string = "data">(
  operationOptions?: ApolloReactHoc.OperationOption<TProps, AgentsStatusQuery, AgentsStatusQueryVariables, AgentsStatusProps<TChildProps, TDataName>>
) {
  return ApolloReactHoc.withQuery<TProps, AgentsStatusQuery, AgentsStatusQueryVariables, AgentsStatusProps<TChildProps, TDataName>>(
    AgentsStatusDocument,
    {
      alias: "agentsStatus",
      ...operationOptions,
    }
  );
}

/**
 * __useAgentsStatusQuery__
 *
 * To run a query within a React component, call `useAgentsStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgentsStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgentsStatusQuery({
 *   variables: {
 *      platformId: // value for 'platformId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useAgentsStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AgentsStatusQuery, AgentsStatusQueryVariables>) {
  return ApolloReactHooks.useQuery<AgentsStatusQuery, AgentsStatusQueryVariables>(AgentsStatusDocument, baseOptions);
}
export function useAgentsStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AgentsStatusQuery, AgentsStatusQueryVariables>) {
  return ApolloReactHooks.useLazyQuery<AgentsStatusQuery, AgentsStatusQueryVariables>(AgentsStatusDocument, baseOptions);
}
export type AgentsStatusQueryHookResult = ReturnType<typeof useAgentsStatusQuery>;
export type AgentsStatusLazyQueryHookResult = ReturnType<typeof useAgentsStatusLazyQuery>;
export type AgentsStatusQueryResult = ApolloReactCommon.QueryResult<AgentsStatusQuery, AgentsStatusQueryVariables>;
export const InitiateVideoCallDocument = gql`
  mutation initiateVideoCall($platformId: ID!, $customerId: ID!, $lat: Float, $lng: Float, $locationId: String) {
    initiateVideoCall(platformId: $platformId, customerId: $customerId, lat: $lat, lng: $lng, locationId: $locationId) {
      token
      callId
    }
  }
`;
export type InitiateVideoCallMutationFn = ApolloReactCommon.MutationFunction<InitiateVideoCallMutation, InitiateVideoCallMutationVariables>;
export type InitiateVideoCallComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<InitiateVideoCallMutation, InitiateVideoCallMutationVariables>,
  "mutation"
>;

export const InitiateVideoCallComponent = (props: InitiateVideoCallComponentProps) => (
  <ApolloReactComponents.Mutation<InitiateVideoCallMutation, InitiateVideoCallMutationVariables> mutation={InitiateVideoCallDocument} {...props} />
);

export type InitiateVideoCallProps<TChildProps = {}, TDataName extends string = "mutate"> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<InitiateVideoCallMutation, InitiateVideoCallMutationVariables>;
} &
  TChildProps;
export function withInitiateVideoCall<TProps, TChildProps = {}, TDataName extends string = "mutate">(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    InitiateVideoCallMutation,
    InitiateVideoCallMutationVariables,
    InitiateVideoCallProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    InitiateVideoCallMutation,
    InitiateVideoCallMutationVariables,
    InitiateVideoCallProps<TChildProps, TDataName>
  >(InitiateVideoCallDocument, {
    alias: "initiateVideoCall",
    ...operationOptions,
  });
}

/**
 * __useInitiateVideoCallMutation__
 *
 * To run a mutation, you first call `useInitiateVideoCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateVideoCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateVideoCallMutation, { data, loading, error }] = useInitiateVideoCallMutation({
 *   variables: {
 *      platformId: // value for 'platformId'
 *      customerId: // value for 'customerId'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useInitiateVideoCallMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<InitiateVideoCallMutation, InitiateVideoCallMutationVariables>
) {
  return ApolloReactHooks.useMutation<InitiateVideoCallMutation, InitiateVideoCallMutationVariables>(InitiateVideoCallDocument, baseOptions);
}
export type InitiateVideoCallMutationHookResult = ReturnType<typeof useInitiateVideoCallMutation>;
export type InitiateVideoCallMutationResult = ApolloReactCommon.MutationResult<InitiateVideoCallMutation>;
export type InitiateVideoCallMutationOptions = ApolloReactCommon.BaseMutationOptions<InitiateVideoCallMutation, InitiateVideoCallMutationVariables>;
export const RemoveScheduledVideoCallDocument = gql`
  mutation removeScheduledVideoCall($platformId: ID!, $customerId: ID!, $callId: ID!) {
    removeScheduledVideoCall(platformId: $platformId, customerId: $customerId, callId: $callId) {
      success
    }
  }
`;
export type RemoveScheduledVideoCallMutationFn = ApolloReactCommon.MutationFunction<
  RemoveScheduledVideoCallMutation,
  RemoveScheduledVideoCallMutationVariables
>;
export type RemoveScheduledVideoCallComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<RemoveScheduledVideoCallMutation, RemoveScheduledVideoCallMutationVariables>,
  "mutation"
>;

export const RemoveScheduledVideoCallComponent = (props: RemoveScheduledVideoCallComponentProps) => (
  <ApolloReactComponents.Mutation<RemoveScheduledVideoCallMutation, RemoveScheduledVideoCallMutationVariables>
    mutation={RemoveScheduledVideoCallDocument}
    {...props}
  />
);

export type RemoveScheduledVideoCallProps<TChildProps = {}, TDataName extends string = "mutate"> = {
  [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveScheduledVideoCallMutation, RemoveScheduledVideoCallMutationVariables>;
} &
  TChildProps;
export function withRemoveScheduledVideoCall<TProps, TChildProps = {}, TDataName extends string = "mutate">(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    RemoveScheduledVideoCallMutation,
    RemoveScheduledVideoCallMutationVariables,
    RemoveScheduledVideoCallProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    RemoveScheduledVideoCallMutation,
    RemoveScheduledVideoCallMutationVariables,
    RemoveScheduledVideoCallProps<TChildProps, TDataName>
  >(RemoveScheduledVideoCallDocument, {
    alias: "removeScheduledVideoCall",
    ...operationOptions,
  });
}

/**
 * __useRemoveScheduledVideoCallMutation__
 *
 * To run a mutation, you first call `useRemoveScheduledVideoCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveScheduledVideoCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeScheduledVideoCallMutation, { data, loading, error }] = useRemoveScheduledVideoCallMutation({
 *   variables: {
 *      platformId: // value for 'platformId'
 *      customerId: // value for 'customerId'
 *      callId: // value for 'callId'
 *   },
 * });
 */
export function useRemoveScheduledVideoCallMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveScheduledVideoCallMutation, RemoveScheduledVideoCallMutationVariables>
) {
  return ApolloReactHooks.useMutation<RemoveScheduledVideoCallMutation, RemoveScheduledVideoCallMutationVariables>(
    RemoveScheduledVideoCallDocument,
    baseOptions
  );
}
export type RemoveScheduledVideoCallMutationHookResult = ReturnType<typeof useRemoveScheduledVideoCallMutation>;
export type RemoveScheduledVideoCallMutationResult = ApolloReactCommon.MutationResult<RemoveScheduledVideoCallMutation>;
export type RemoveScheduledVideoCallMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RemoveScheduledVideoCallMutation,
  RemoveScheduledVideoCallMutationVariables
>;

export interface IntrospectionResultData {
  __schema: {
    types: {
      kind: string;
      name: string;
      possibleTypes: {
        name: string;
      }[];
    }[];
  };
}
const result: IntrospectionResultData = {
  __schema: {
    types: [
      {
        kind: "INTERFACE",
        name: "PairedChannel",
        possibleTypes: [
          {
            name: "PairedChannelBlank",
          },
          {
            name: "PairedChannelFacebook",
          },
          {
            name: "PairedChannelTwitter",
          },
          {
            name: "PairedChannelVideo",
          },
          {
            name: "PairedChannelWhatsapp",
          },
        ],
      },
      {
        kind: "INTERFACE",
        name: "MeccaConversationModel",
        possibleTypes: [
          {
            name: "MeccaTextConversationModel",
          },
          {
            name: "MeccaBlankConversationModel",
          },
          {
            name: "MeccaVideoConversationModel",
          },
        ],
      },
      {
        kind: "INTERFACE",
        name: "EventModel",
        possibleTypes: [
          {
            name: "ConversationCreatedEvent",
          },
          {
            name: "ConversationUpdatedEvent",
          },
          {
            name: "ConversationForwardedEvent",
          },
          {
            name: "ConversationReadedEvent",
          },
          {
            name: "ConversationFlaggedEvent",
          },
          {
            name: "ConversationUnflaggedEvent",
          },
          {
            name: "ConversationArchivedEvent",
          },
          {
            name: "ConversationClosedEvent",
          },
          {
            name: "ConversationStatusChangedEvent",
          },
          {
            name: "ConversationPriorityChangedEvent",
          },
        ],
      },
      {
        kind: "INTERFACE",
        name: "Notification",
        possibleTypes: [
          {
            name: "NotificationInvoice",
          },
          {
            name: "NotificationConversation",
          },
        ],
      },
      {
        kind: "INTERFACE",
        name: "Search",
        possibleTypes: [
          {
            name: "SearchMessage",
          },
          {
            name: "SearchAgentMessage",
          },
          {
            name: "SearchAttachment",
          },
          {
            name: "SearchCustomerCrmContact",
          },
          {
            name: "SearchCustomerFixedInstruction",
          },
          {
            name: "SearchCustomerTemporaryInstruction",
          },
        ],
      },
      {
        kind: "INTERFACE",
        name: "MeccaTextConversationPreviewModel",
        possibleTypes: [
          {
            name: "MeccaTextConversationPreviewModelTextAndAttachments",
          },
          {
            name: "MeccaTextConversationPreviewModelTextAndAttachmentsWithStructuredMessage",
          },
        ],
      },
      {
        kind: "INTERFACE",
        name: "Conversation",
        possibleTypes: [
          {
            name: "BlankConversation",
          },
          {
            name: "TextConversation",
          },
          {
            name: "VideoConversation",
          },
        ],
      },
      {
        kind: "INTERFACE",
        name: "TextConversationPreview",
        possibleTypes: [
          {
            name: "TextConversationPreviewTextAndAttachments",
          },
          {
            name: "TextConversationPreviewTextAndAttachmentsWithStructuredMessage",
          },
        ],
      },
    ],
  },
};
export default result;
