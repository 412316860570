import React from "react";
import { Modal, Overlay } from "../../pages/video-call/video-call-page.style";
import { Button } from "../button/button";
import { VideoCallSettings } from "../../generated/graphql";
import { useTranslation } from "react-i18next";

export interface ModalProps {
  show: boolean;
  videoCallSettings: VideoCallSettings;
  callHandler: (value: boolean) => any;
}

export const ModalComponent: React.FunctionComponent<ModalProps> = ({ videoCallSettings, callHandler, show, children }) => {
  const { t } = useTranslation();
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <Overlay onClick={() => callHandler(false)}>
      <Modal>
        <div className={showHideClassName}>
          <section className="modal-main">
            <div className={"close"} onClick={() => callHandler(false)}>
              <img src={require("../../assets/icons/close.svg")} alt="null" className="modal-close-icon" />
            </div>
            {children}
            <Button onClick={() => callHandler(false)} colors={videoCallSettings?.colors}>
              {t("back")}
            </Button>
          </section>
        </div>
      </Modal>
    </Overlay>
  );
};
