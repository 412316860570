import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { IntroPage } from "./pages/intro/intro-page";
import "./App.css";
import { NotFound } from "./pages/not-found/not-found";
function App() {
  return (
    <Router>
      <Switch>
        <Route path="/:platformId/:customerId/:locationId?" exact={true} component={IntroPage} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
